(function () {
    'use strict';

    angular
        .module('app')
        .controller('ResetController', ResetController);

    // Define controller dependencies.
    ResetController.$inject = ['$rootScope', '$stateParams', '$timeout', '$window', 'Restangular'];

    /**
     * Controller for 'password-reset' state.
     *
     * @param $rootScope
     * @param $stateParams
     * @param $timeout
     * @param $window
     * @param Restangular
     * @constructor
     */
    function ResetController($rootScope, $stateParams, $timeout, $window, Restangular) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.account = undefined;
        vm.domain = '@sub.jangkarpacific.com';
        vm.password = undefined;
        vm.passwordConfirmation = undefined;
        vm.domains = [
            {display: '@sub.jangkarpacific.com', value: '@sub.jangkarpacific.com'},
            {display: '@srg.jangkarpacific.com', value: '@srg.jangkarpacific.com'},
            {display: '@jangkarpacific.com', value: '@jangkarpacific.com'},
            {display: 'others', value: ''},
        ];

        /**
         * Bindable methods.
         */
        vm.reset = reset;
        vm.changeDomain = changeDomain;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Send password reset request.
         */
        function reset() {
            Restangular.all('password/reset')
                .post({
                    token: $stateParams.token,
                    email: vm.account + vm.domain,
                    password: vm.password,
                    password_confirmation: vm.passwordConfirmation
                })
                .then(function () {
                    $timeout(function () {
                        $window.location.href = $rootScope.global.appsUrl;
                    }, 2000);
                });
        }

        /**
         * Change selected domain.
         *
         * @param domain
         */
        function changeDomain(domain) {
            vm.domain = domain;
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
        }

        activate();
    }
})();
