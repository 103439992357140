(function () {
    angular
        .module('app')
        .factory('CRUDFactory', CRUDFactory);

    // Define factory dependencies.
    CRUDFactory.$inject = ['$q', '$rootScope', 'Restangular', 'SanitizerFactory'];

    /**
     * Factory for executing CRUD commands to the API server.
     *
     * @param $q
     * @param $rootScope
     * @param Restangular
     * @constructor
     */
    function CRUDFactory($q, $rootScope, Restangular, SanitizerFactory) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members.
         * --------------------------------------------
         */
        var service = {
            // Properties
            resource: null,
            // Methods
            create: create,
            read: read,
            edit: edit,
            update: update,
            destroy: destroy,
            print: print
        };

        return service;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members implementations.
         * --------------------------------------------
         */

        /**
         * Create a resource.
         *
         * @param entity
         * @returns {*}
         */
        function create(entity) {
            return Restangular.all(service.resource).post(SanitizerFactory.sanitizeSent(entity))
                .then(function (result) {
                    $rootScope.$broadcast('CRUDFactory.createSuccess', {id: result.id});
                    return result.id;
                })
                .catch(function () {
                    return $q.reject();
                });
        }

        /**
         * Read a resource.
         *
         * @param id
         * @returns {*}
         */
        function read(id) {
            return Restangular.one(service.resource, id).get({d: 1})
                .then(function (result) {
                    $rootScope.$broadcast('CRUDFactory.readSuccess', result.original);
                    return result.original;
                }, function () {
                    return $q.reject();
                });
        }

        /**
         * Edit a resource.
         *
         * @param id
         * @returns {*}
         */
        function edit(id) {
            return Restangular.one(service.resource, id).customGET('edit')
                .then(function (result) {
                    var sanitized = SanitizerFactory.sanitizeReceived(result.original);
                    $rootScope.$broadcast('CRUDFactory.editSuccess', sanitized);
                    return sanitized;
                }, function () {
                    return $q.reject();
                });
        }

        /**
         * Update a resource.
         *
         * @param entity
         * @returns {*}
         */
        function update(entity) {
            return Restangular.restangularizeElement('', SanitizerFactory.sanitizeSent(angular.copy(entity)), service.resource)
                .put()
                .then(function () {
                    $rootScope.$broadcast('CRUDFactory.updateSuccess');
                })
                .catch(function () {
                    return $q.reject();
                });
        }

        /**
         * Destroy resources.
         *
         * @param ids
         * @returns {*}
         */
        function destroy(ids) {
            if (ids.length > 1) {
                // Destroy multiple resources.
                return Restangular.all(service.resource).customDELETE(
                    '0',
                    {q: angular.toJson(ids, false)}
                )
                    .then(function () {
                        $rootScope.$broadcast('CRUDFactory.destroySuccess');
                    })
                    .catch(function () {
                        return $q.reject();
                    });
            } else {
                // Destroy single resource.
                return Restangular.restangularizeElement('', {id: ids[0]}, service.resource)
                    .remove()
                    .then(function () {
                        $rootScope.$broadcast('CRUDFactory.destroySuccess');
                    })
                    .catch(function () {
                        return $q.reject();
                    });
            }
        }

        /**
         * Print a resource.
         */
        function print(id) {
            return Restangular.withConfig(function (RestangularConfigurer) { RestangularConfigurer.setFullResponse(true); })
                .one(service.resource, id).withHttpConfig({responseType: 'blob'}).customGET('print', {
                    q: undefined,
                    reportName: '',
                    reportFormat: 'PDF',
                    user: $rootScope.global.user.id,
                    workgroup: $rootScope.global.user.workgroups[helpers.getLocalStorageItem('activeWorkgroupIndex')[$rootScope.global.user.applications[0].id]].id
                })
                .then(function (result) {
                    var filename = result.headers('Content-Disposition').split(';')[1].split('=')[1].replace(/"/g, '');
                    saveAs(result.data, filename);
                }, function () {
                    return $q.reject();
                });
        }
    }
})();
