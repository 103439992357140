(function () {
    'use strict';

    angular
        .module('app')
        .controller('DataListController', DataListController);

    // Define controller dependencies.
    DataListController.$inject = ['$rootScope', '$scope', '$state', 'DataListFactory', 'FocusService', 'hotkeys'];

    /**
     * Controller for 'data-list' state-view.
     *
     * @param $rootScope
     * @param $scope
     * @param $state
     * @param DataListFactory
     * @param FocusService
     * @param hotkeys
     * @constructor
     */
    function DataListController($rootScope, $scope, $state, DataListFactory, FocusService, hotkeys) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.factory = DataListFactory;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Set active data list factory to the 'main' factory, because
            // lookup component will create different ('ad-hoc') instance
            // of DataListFactory and use it as the active factory.
            $rootScope.global.activeDataListFactory = DataListFactory;

            // Prevent user to change state if data list is still loading.
            $rootScope.$on('$stateChangeStart',
                function(event, toState, toParams, fromState, fromParams){
                    if ($rootScope.global.activeDataListFactory.isBusy()) {
                        event.preventDefault();
                    }
                }
            );

            // Binding keyboard shortcuts.
            hotkeys.bindTo($scope)
                .add({
                    combo: 'ctrl+alt+e',
                    description: 'Refresh data list',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { $rootScope.global.activeDataListFactory.search(); }
                })
                .add({
                    combo: 'ctrl+alt+f',
                    description: 'Type in quick search',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { FocusService.focus($rootScope.global.activeDataListFactory.contextSelector + ' .quick-search'); }
                })
                .add({
                    combo: 'shift+ctrl+alt+f',
                    description: 'Open advanced search',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { $rootScope.global.activeDataListFactory.showAdvancedSearch();; }
                })
                .add({
                    combo: 'ctrl+alt+.',
                    description: 'Go to next page',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { $rootScope.global.activeDataListFactory.next(); }
                })
                .add({
                    combo: 'ctrl+alt+,',
                    description: 'Go to previous page',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { $rootScope.global.activeDataListFactory.previous(); }
                })
                .add({
                    combo: 'shift+ctrl+alt+,',
                    description: 'Go to first page',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { $rootScope.global.activeDataListFactory.first(); }
                })
                .add({
                    combo: 'shift+ctrl+alt+.',
                    description: 'Go to last page',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { $rootScope.global.activeDataListFactory.last(); }
                })
                .add({
                    combo: 'ctrl+alt+g',
                    description: 'Focus to grid',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { $rootScope.global.activeDataListFactory.focus(); }
                });
        }

        activate();
    }
})();
