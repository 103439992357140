/**
 * Created by DAVIDHIU on 02/27/2015.
 */

(function () {
    angular
        .module('app')
        .directive('delay', delay);

    delay.$inject = ['$timeout'];

    /* @ngInject */
    function delay($timeout) {
        return {
            restrict: 'A',
            scope: true,
            compile: function (element, attributes) {
                var expression = attributes['ngChange'];
                var parentForm = element[0].parentElement.parentElement.attributes.name.value;

                if (!expression)
                    return;

                var ngModel = attributes['ngModel'];
                if (ngModel) attributes['ngModel'] = '$parent.' + ngModel;
                attributes['ngChange'] = '$$delay.execute()';

                return {
                    post: function (scope, element, attributes) {
                        scope.$$delay = {
                            expression: expression,
                            delay: scope.$eval(attributes['delay']),
                            execute: function () {
                                var state = scope.$$delay;
                                state.then = Date.now();
                                $timeout(function () {
                                    if (Date.now() - state.then >= state.delay && scope.$parent[parentForm].$valid) {
                                        scope.$parent.$eval(expression);
                                        scope.$parent[parentForm].$setSubmitted();
                                    }
                                }, state.delay);
                            }
                        };
                    }
                }
            }
        };
    }
})();
