(function () {
    'use strict';

    angular
        .module('app')
        .factory('ErrorMessageHandlerFactory', ErrorMessageHandlerFactory);

    // Define factory dependencies.
    ErrorMessageHandlerFactory.$inject = ['$mdDialog', '$mdToast', '$rootScope'];

    /**
     * Factory for error message handling.
     *
     * @param $mdDialog
     * @param $mdToast
     * @param $rootScope
     * @constructor
     */
    function ErrorMessageHandlerFactory($mdDialog, $mdToast, $rootScope) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */
        var redirected;

        /**
         * --------------------------------------------
         * Factory members.
         * --------------------------------------------
         */
        var service = {
            // Methods.
            handle: handle,
            showMsgThenRedirect: showMsgThenRedirect
        };

        return service;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members implementations.
         * --------------------------------------------
         */

        /**
         * Handle error response.
         *
         * @param rejection
         */
        function handle(rejection) {
            // Skip handling if already redirected.
            if (redirected) return;

            // Get error message if response is Blob.
            if (rejection.data instanceof Blob) {
                var reader = new FileReader();
                reader.addEventListener("loadend", function () {
                    var clone = angular.copy(rejection);
                    clone.data = JSON.parse(reader.result);
                    $rootScope.$apply(function () {
                        handle(clone);
                    });
                });
                reader.readAsText(rejection.data);
                return;
            }

            // Build error message.
            var error = {
                status: rejection.status,
                statusText: rejection.statusText,
                message: ''
            };

            if (rejection.status == -1) {
                error = {
                    status: -1,
                    statusText: 'Fatal Error',
                    message: 'No response'
                }
            } else {
                // Get the first displyed message.
                var firstMessage = rejection.data.message;

                if (firstMessage == undefined) {
                    firstMessage = rejection.data[Object.keys(rejection.data)[0]];
                }

                error.message = (Array.isArray(firstMessage)) ? firstMessage.join("; ") : firstMessage;
            }

            if (
                rejection.status === 400 || // Bad requrest
                rejection.status === 401    // Unauthorized
            ) {
                localStorage.clear();
                service.showMsgThenRedirect(error);
                redirected = true;
            } else {
                $mdToast.show({
                    controller: ['error', function (error) {
                        var vm = this;
                        vm.error = error;
                    }],
                    controllerAs: 'vm',
                    templateUrl: 'shared/message-handler/error-message.view.html',
                    parent: angular.element('body'),
                    hideDelay: 3000,
                    position: 'bottom left',
                    resolve: {
                        error: function () {
                            return error;
                        }
                    }
                });
            }
        }

        /**
         * Show error message and then redirect to login.
         *
         * @param error
         */
        function showMsgThenRedirect(error) {
            $mdDialog.show({
                templateUrl: 'shared/message-handler/error-message-redirect.view.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                resolve: {
                    error: function () {
                        return angular.copy(error);
                    }
                },
                controller: ['$interval', '$window', 'error',
                    function ($interval, $window, error) {
                        var vm = this;

                        vm.timeout = 9;
                        vm.redirect = redirect;
                        vm.error = error;

                        activate();

                        ////////////////

                        function activate() {
                            var inv = $interval(function () {
                                vm.timeout--;
                                if (vm.timeout == 0) {
                                    $interval.cancel(inv);
                                    vm.redirect();
                                }
                            }, 1000);
                        }

                        function redirect() {
                            $window.location.href = $rootScope.global.loginUrl;
                        }
                    }
                ],
                controllerAs: 'vm'
            });
        }
    }
})();
