(function () {
    'use strict';

    angular
        .module('app')
        .factory('PermissionFactory', PermissionFactory);

    // Define factory dependencies.
    PermissionFactory.$inject = ['$rootScope', 'ErrorMessageHandlerFactory'];

    /**
     * Factory for applying permission payload.
     *
     * @param $rootScope
     * @constructor
     */
    function PermissionFactory($rootScope, ErrorMessageHandlerFactory) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members.
         * --------------------------------------------
         */

        var service = {
            // Methods.
            applyPayload: applyPayload
        };

        return service;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members implementations.
         * --------------------------------------------
         */

        /**
         * Apply permission payload.
         *
         * @param params
         */
        function applyPayload(params) {
            var user = $rootScope.global.user;
            var APIPartners = $rootScope.global.APIPartners;

            try {
                if (user != undefined) {
                    params['payload'] = {
                        appId: user.applications[0].id,
                        versionId: user.applications[0].versions[0].id,
                        roleIds: (function () {
                            var ids = [];
                            user.roles.forEach(function (value, index, array) {
                                ids.push(value.id);
                            });
                            return ids;
                        })(),
                        storageId: user.workgroups[helpers.getLocalStorageItem('activeWorkgroupIndex')[user.applications[0].id] || 0].storages[helpers.getLocalStorageItem('activeStorageIndex')[user.applications[0].id] || 0].id,
                        workgroupId: user.workgroups[helpers.getLocalStorageItem('activeWorkgroupIndex')[user.applications[0].id] || 0].id,
                        // filterWorkgroups: (function () {
                        filterWorkgroupIds: (function () {
                            var ids = [];
                            (helpers.getLocalStorageItem('filterWorkgroupIndexes') || []).forEach(function (value, index, array) {
                                var workgroup = user.workgroups[value];
                                // ids.push({
                                //     id: workgroup.id
                                // });
                                ids.push(workgroup.id);
                            });
                            return ids;
                        })()
                    };
                }

                if (APIPartners != undefined) {
                    params['APIPartnersPayload'] = (function () {
                        var APIPartnersPayload = {};
                        for (var APIPartnerCode in APIPartners) {
                            var APIPartner = APIPartners[APIPartnerCode];
                            APIPartnersPayload[APIPartnerCode] = {
                                appId: APIPartner.applications[0].id,
                                versionId: APIPartner.applications[0].versions[0].id,
                                roleIds: (function () {
                                    var ids = [];
                                    APIPartner.roles.forEach(function (value) {
                                        ids.push(value.id);
                                    });
                                    return ids;
                                })(),
                                storageId: APIPartner.workgroups[helpers.getLocalStorageItem('activeWorkgroupIndex')[APIPartner.applications[0].id] || 0].storages[helpers.getLocalStorageItem('activeStorageIndex')[APIPartner.applications[0].id] || 0].id,
                                workgroupId: APIPartner.workgroups[helpers.getLocalStorageItem('activeWorkgroupIndex')[APIPartner.applications[0].id] || 0].id,
                                // Also send workgroup name as payload because desktop systems use workgroup name (e.g. JPSUBEOR01) instead of workgroup id (e.g. 1)
                                // workgroupName: APIPartner.workgroups[helpers.getLocalStorageItem('activeWorkgroupIndex')[APIPartner.applications[0].id] || 0].name
                            };
                        }
                        return APIPartnersPayload;
                    })();
                }
            } catch (e) {
                localStorage.clear();
                ErrorMessageHandlerFactory.showMsgThenRedirect({
                    status: -1,
                    statusText: 'Fatal Error',
                    message: 'permission_error'
                });
            }
        }
    }
})();
