/**
 * Created by DAVIDHIU on 02/13/2015.
 */

(function () {
    angular
        .module('app')
        .directive('eventFocus', eventFocus);

    eventFocus.$inject = ['FocusService'];

    /* @ngInject */
    function eventFocus(FocusService) {
        return function (scope, elem, attr) {
            elem.on(attr.eventFocus, function () {
                FocusService.focus(attr.eventFocusSelector);
            });

            // Removes bound events in the element itself
            // when the scope is destroyed
            scope.$on('$destroy', function () {
                elem.off(attr.eventFocus);
            });
        };
    }
})();
