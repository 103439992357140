(function () {
    'use strict';

    angular
        .module('app')
        .controller('AdvancedSearchController', AdvancedSearchController);

    // Define controller dependencies.
    AdvancedSearchController.$inject = ['$mdDialog', '$scope', '$timeout', 'AdvancedSearchFactory', 'FocusService', 'qAdv', 'resource', 'resourceFields'];

    /**
     * Controller for advanced search dialog.
     *
     * @param $mdDialog
     * @param $scope
     * @param AdvancedSearchFactory
     * @param $timeout
     * @param FocusService
     * @param qAdv
     * @param resource
     * @param resourceFields
     * @constructor
     */
    function AdvancedSearchController($mdDialog, $scope, $timeout, AdvancedSearchFactory, FocusService, qAdv, resource, resourceFields) {
        /* qAdv format

         qAdv: {
             s : {b: '', g: [
                    {f: 'field', i: 0}, ...
                ], i: 0, lastI: 0},
             w : {b: '', g: [
                    {b: 'and', f: 'field', o:'=', v='value', i: 0}, {b: 'or', g: [...], i: 1}, ...
                 ], i: 0, lastI: 0},
             h : [
                     {b: 'or', n: 0, r: 'relation_name1', g: [...], i: 0, lastI: 0},
                     {b: 'or', n: 0, r: 'relation_name2', g: [...], i: 0, lastI: 0},
                     ...
                 ],
             o : {b: '', g: [
                    {f: 'field', 'o': 'asc', i: 0}, ...
                 ], i: 0, lastI: 0}
         }

         extracted qAdv: {
         s : [
                {f: 'field'}, ...
             ],
         w : [
                {b: 'and', f: 'field', o:'=', v:'value'}, {b: 'or', g: [...]}, ...
             ],
         h : [
                 {b: 'or', n: 0, r: 'relation_name1', g: [...],},
                 {b: 'or', n: 0, r: 'relation_name2', g: [...],},
                 ...
             ],
         o : [
                {f: 'field', 'o': 'asc'}, ...
             ]
         }

         */

        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.resource = resource;
        vm.resourceFields = resourceFields;
        vm.whereTabHeadings = (function () {
            var headings = {};
            angular.forEach(resourceFields, function (value) {
                headings[value.relation] = value.relation;
            });
            return headings;
        })();
        vm.logicals = ['and', 'or'];
        vm.operators = ['=', '!=', '<', '>', '<=', '>=', 'LIKE', 'NOT LIKE', 'IN', 'NOT IN', 'BETWEEN', 'NOT BETWEEN', 'IS NULL', 'IS NOT NULL'];
        vm.orders = ['asc', 'desc'];
        vm.currentWhereNode = undefined;
        vm.currentWhereFieldNode = undefined;
        vm.currentWhereGroupNode = undefined;
        vm.currentOrderNode = undefined;
        vm.currentOrderFieldNode = undefined;
        vm.qAdv = {};
        vm.showWhereBuilder = [];
        vm.showOrderBuilder = false;

        /**
         * Bindable methods.
         */
        vm.changeWhereNode = changeWhereNode;
        vm.insertWhereNode = insertWhereNode;
        vm.deleteCurrentWhereNode = deleteCurrentWhereNode;
        vm.changeOrderNode = changeOrderNode;
        vm.insertOrderNode = insertOrderNode;
        vm.deleteCurrentOrderNode = deleteCurrentOrderNode;
        vm.orderFieldSelectable = orderFieldSelectable;
        vm.refreshWhereTabHeadings = refreshWhereTabHeadings;
        vm.whereTabChanged = whereTabChanged;
        vm.generateAutoComplete = generateAutoComplete;
        vm.clearAutoCompleteItemDescription = clearAutoCompleteItemDescription;
        vm.clear = clear;
        vm.close = close;
        vm.cancel = cancel;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * Get default where field node.
         *
         * @returns {{b: string, f, o: string, v: undefined}}
         */
        function getCurrentWhereFieldNodeDefault() {
            return {
                b: 'and',
                f: (function () {
                    return _.get(_.find(resourceFields, 'relation', 'this'), 'fields[0].name');
                })(),
                o: '=',
                v: undefined
            }
        };

        /**
         * Get default order field node.
         *
         * @returns {{f, o: string}}
         */
        function getCurrentOrderFieldNodeDefault() {
            return {
                f: (function () {
                    if (vm.qAdv === undefined) {
                        return _.get(_.find(resourceFields, 'relation', 'this'), 'fields[0].name');
                    } else {
                        var i = 0,
                            fields = _.get(_.find(resourceFields, 'relation', 'this'), 'fields');
                        while (!orderFieldSelectable(fields[i]['name'])) {
                            if (i === fields.length - 1) {
                                return '';
                            }
                            i++;
                        }
                        return _.get(_.find(resourceFields, 'relation', 'this'), ['fields', i, 'name']);
                    }
                })(),
                o: 'asc'
            };
        };

        /**
         * Delete a node with specified id from a node.
         *
         * @param nodes
         * @param id
         */
        function deleteNode(node, id) {
            // Get child nodes of the source node.
            var childNodes = (angular.isArray(node)) ? node : [node];

            // Search for target node to be deleted.
            for (var i = 0; i < childNodes.length; i++) {
                if (childNodes[i].i == id) {
                    // Target found, delete it and done!
                    childNodes.splice(i, 1);
                    break;
                } else {
                    if (childNodes[i].g) {
                        // Found a group node, go recursive.
                        deleteNode(childNodes[i].g, id);
                    }
                }
            }
        }

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Change currently selected where node.
         *
         * @param node
         */
        function changeWhereNode(node) {
            vm.currentWhereNode = node;

            if (vm.currentWhereNode.f) {
                vm.currentWhereFieldNode = node;
                // If selected where node is a where field node, set current where group node undefined.
                vm.currentWhereGroupNode = undefined;
            } else {
                vm.currentWhereGroupNode = node;
                // If selected where node is a where group node, set current where field node to default.
                vm.currentWhereFieldNode = getCurrentWhereFieldNodeDefault();
            }
        }

        /**
         * Insert new where node (field or group) into a where group node.
         *
         * @param relation
         * @param isGroup
         * @param logicalGroup
         */
        function insertWhereNode(relation, isGroup, logicalGroup) {
            if (!isGroup &&
                (angular.isUndefined(vm.currentWhereFieldNode.v) || vm.currentWhereFieldNode.v == '') &&
                !helpers.stringContains('NULL', vm.currentWhereFieldNode.o)) return;

            var rootGroup;
            if (relation == 'this') {
                // Get "main where" root group
                rootGroup = vm.qAdv.w;
            } else {
                // Get "where has relation" root group
                rootGroup = _.find(vm.qAdv.h, 'r', relation);
            }

            // Increment last where node id.
            rootGroup.lastI++;

            if (!isGroup) {
                // Insert new where field node.
                vm.currentWhereFieldNode.i = rootGroup.lastI;
                vm.currentWhereNode.g.push(vm.currentWhereFieldNode);

                // Set current where node to the just being inserted where group node.
                changeWhereNode(vm.currentWhereNode);
            } else {
                // Insert new where group node.
                var newGroup = {
                    b: logicalGroup,
                    g: [],
                    i: rootGroup.lastI
                };
                vm.currentWhereNode.g.push(newGroup);

                // Set current where node to the newly created where group node.
                changeWhereNode(newGroup);
            }

            // Refresh where tab headings.
            refreshWhereTabHeadings();

            // Focus to input next node
            FocusService.focus('#where-focused-element');
        }

        /**
         * Delete current where node.
         *
         * @param relation
         * @param fastDelete
         */
        function deleteCurrentWhereNode(relation, fastDelete) {
            // Where root node can not be deleted.
            if (vm.currentWhereNode.i == 0) return;

            var rootGroup;
            if (relation == 'this') {
                // Get "main where" root group
                rootGroup = vm.qAdv.w;
            } else {
                // Get "where has relation" root group
                rootGroup = _.find(vm.qAdv.h, 'r', relation);
            }

            // Delete where node from where root node.
            deleteNode(rootGroup, vm.currentWhereNode.i);

            if (!fastDelete) {
                // Set current where node to the where root node.
                changeWhereNode(rootGroup);

                // Refresh where tab headings.
                refreshWhereTabHeadings();
            }
        }

        /**
         * Change currently selected order node.
         *
         * @param node
         */
        function changeOrderNode(node) {
            vm.currentOrderNode = node;
            if (node.f)
                vm.currentOrderFieldNode = node;
            else
                vm.currentOrderFieldNode = getCurrentOrderFieldNodeDefault();
        }

        /**
         * Insert new order node.
         */
        function insertOrderNode() {
            // Do not insert node if field is invalid.
            if (!orderFieldSelectable(vm.currentOrderFieldNode.f)) {
                return;
            }

            // Increment last order node id.
            vm.qAdv.o.lastI++;

            // Insert new order field node.
            vm.currentOrderFieldNode.i = vm.qAdv.o.lastI;
            vm.currentOrderNode.g.push(vm.currentOrderFieldNode);

            // Set current order node to the order root node.
            changeOrderNode(vm.qAdv.o);

            // Focus to input next node
            FocusService.focus('#order-focused-element');
        }

        /**
         * Delete current order node.
         *
         * @param fastDelete
         */
        function deleteCurrentOrderNode(fastDelete) {
            // Order root node can not be deleted.
            if (vm.currentOrderNode.i == 0) return;

            // Delete order node from where root node.
            deleteNode(vm.qAdv.o, vm.currentOrderNode.i);

            if (!fastDelete) {
                // Set current order node to the order root node.
                changeOrderNode(vm.qAdv.o);
            }
        }

        /**
         * Check if field is selectable.
         *
         * @param newField
         * @returns {boolean}
         */
        function orderFieldSelectable(field) {
            // Check if field is valid. Clicking 'Insert Field"
            // if all fields have already been selected will
            // result in field with empty string value.
            if (field == '') return false;

            // Check if field has been selected.
            for (var i = 0; i < vm.qAdv.o.g.length; i++) {
                if (vm.qAdv.o.g[i].f == field) {
                    return false;
                }
            }

            // The field is selectable.
            return true;
        }

        /**
         * Refresh where tab headings to add * sign if corresponding where tree is not empty.
         */
        function refreshWhereTabHeadings() {
            // "main where" heading.
            if (vm.qAdv.w.g.length > 0) {
                vm.whereTabHeadings['this'] = 'this *';
            } else {
                vm.whereTabHeadings['this'] = 'this';
            }

            // "where has relation" headings.
            angular.forEach(vm.qAdv.h, function (value, key) {
                if (value.g.length > 0) {
                    vm.whereTabHeadings[value.r] = _.snakeCase(value.r).replace(/_/g, ' ') + ' *';
                } else {
                    vm.whereTabHeadings[value.r] = _.snakeCase(value.r).replace(/_/g, ' ');
                }
            });
        }

        /**
         * Select proper where root node when where tab has changed.
         *
         * @param relation
         */
        function whereTabChanged(relation) {
            if (relation == 'this') {
                changeWhereNode(vm.qAdv.w);
            } else {
                changeWhereNode(_.find(vm.qAdv.h, 'r', relation));
            }
        }

        /**
         * Generate auto complete suggested values.
         */
        function generateAutoComplete(resourceField, currentField, searchText) {
            var values = _.get(_.find(resourceField, {'name': currentField}), 'values');
            var results = _.filter(values, function (value) {
                return angular.lowercase(value.display).indexOf(angular.lowercase(searchText)) >= 0;
            });
            return results ? results : [];
        }

        /**
         * Clear auto complete item's description.
         */
        function clearAutoCompleteItemDescription(text) {
            return text.replace(/\s*\(.*\)$/i, '');
        }

        /**
         * Set qAdv to its default.
         */
        function clear() {
            for(var i = vm.qAdv.w.g.length - 1; i >= 0; i--) {
                vm.changeWhereNode(vm.qAdv.w.g[i]);
                vm.deleteCurrentWhereNode('this', i === 0 ? false : true);
            }

            vm.qAdv.h.forEach(function (whereHas) {
                for(var i = whereHas.g.length - 1; i >= 0; i--) {
                    vm.changeWhereNode(whereHas.g[i]);
                    vm.deleteCurrentWhereNode(whereHas.r, i === 0 ? false : true);
                }
            });

            for(var i = vm.qAdv.o.g.length - 1; i >= 0; i--) {
                vm.changeOrderNode(vm.qAdv.o.g[i]);
                vm.deleteCurrentOrderNode(i === 0 ? false : true);
            }
        }

        /**
         * Close dialog and pass current qAdv for later show.
         */
        function close() {
            $mdDialog.hide(vm.qAdv);
        }

        /**
         * Cancel dialog.
         */
        function cancel() {
            $mdDialog.cancel();
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Preset qAdv with default value or previous value (last shown).
            vm.qAdv = qAdv;

            // Select where root node
            changeWhereNode(vm.qAdv.w);

            // Select order root node
            changeOrderNode(vm.qAdv.o);

            // Clear where value if selected operator is "IS NULL" or "IS NOT NULL"
            $scope.$watch('vm.currentWhereFieldNode.o', function (newValue) {
                if (newValue.indexOf('NULL') >= 0) {
                    vm.currentWhereFieldNode.v = '';
                }
            });

            // Refresh where tab headings.
            $timeout(function () {
                refreshWhereTabHeadings();
            }, 500);
        }

        activate();
    }
})();
