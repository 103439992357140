(function () {
    angular
        .module('app')
        .factory('AdvancedSearchFactoryProvider', AdvancedSearchFactoryProvider)
        .factory('AdvancedSearchFactory', ['AdvancedSearchFactoryProvider', function (AdvancedSearchFactoryProvider) {
            // Get factory instance from the provider.
            return AdvancedSearchFactoryProvider.getInstance();
        }]);

    // Define factory provider dependencies.
    AdvancedSearchFactoryProvider.$inject = ['$mdDialog', '$rootScope'];

    /**
     * Factory provider for displaying advanced search dialog.
     *
     * @param $mdDialog
     * @param $rootScope
     * @constructor
     */
    function AdvancedSearchFactoryProvider($mdDialog, $rootScope) {

        /**
         * Service provider.
         *
         * @constructor
         */
        var ServiceProvider = function () {
            /**
             * --------------------------------------------
             * Private variables.
             * --------------------------------------------
             */

            /**
             * --------------------------------------------
             * Factory members.
             * --------------------------------------------
             */

            var service = {
                // Properties
                resource: null,
                resourceFields: [],
                qAdvPreset: {},
                // Methods
                show: show,
                getQAdvDefault: getQAdvDefault,
                extractQAdv: extractQAdv,
                reset: reset
            };

            return service;

            /**
             * --------------------------------------------
             * Private methods.
             * --------------------------------------------
             */

            /**
             * Get sanitized qAdv component.
             *
             * @param items
             * @returns {*}
             */
            function getQAdvComponent(items) {
                angular.forEach(items, function (item) {
                    if (item.g) {
                        delete item.i;
                        delete item.lastI;
                        getQAdvComponent(item.g);
                    } else {
                        delete item.i;
                        delete item.lastI;
                    }
                });
                return items;
            }

            /**
             * Apply the preset qAdv.
             */
            function applyPresetQAdv(qAdv) {
                if (service.qAdvPreset.s !== undefined) {
                    qAdv.s.g = angular.copy(service.qAdvPreset.s);
                }
                if (service.qAdvPreset.w !== undefined) {
                    qAdv.w.g = angular.copy(service.qAdvPreset.w);
                }
                if (service.qAdvPreset.h !== undefined) {
                    qAdv.h = angular.copy(service.qAdvPreset.h);
                }
                if (service.qAdvPreset.o !== undefined) {
                    qAdv.o.g = angular.copy(service.qAdvPreset.o);
                }
                return buildQAdvIndex(qAdv);
            }

            /**
             * Build qAdv node index.
             *
             * @param qAdv
             */
            function buildQAdvIndex(qAdv) {
                angular.forEach([qAdv.s, qAdv.w, qAdv.o].concat(qAdv.h), function (node) {
                    node.lastI = 0;
                    setIndex(node, node);
                });
                return qAdv;
            }

            /**
             * Set index for each qAdv node.
             *
             * @param node
             * @param lastI
             */
            function setIndex(node, rootNode) {
                node.i = rootNode.lastI;
                rootNode.lastI++;
                if (node.g !== undefined) {
                    // group node
                    angular.forEach(node.g, function (node) {
                        setIndex(node, rootNode);
                    });
                }
            }

            /**
             * --------------------------------------------
             * Factory members implementations.
             * --------------------------------------------
             */

            /**
             * Show advanced search dialog.
             *
             * @returns Promise
             */
            function show() {
                // Get stored qAdv.
                var getStoredQAdv = function () {
                    // Retrieve stored qAdv.
                    var storedQAdv = helpers.getLocalStorageItem('qAdv.' + service.resource);

                    // Check for invalid stored qAdv.
                    if (!_.isObject(storedQAdv)) storedQAdv = getQAdvDefault();

                    return storedQAdv;
                };

                // Show the advanced search dialog
                var show = function (qAdv) {
                    return $mdDialog.show({
                        templateUrl: 'shared/advanced-search/advanced-search.view.html',
                        controller: 'AdvancedSearchController',
                        controllerAs: 'vm',
                        clickOutsideToClose: true,
                        // We use new isolate scope also with lodash
                        scope: angular.extend($rootScope.$new(true), {_: _}),
                        resolve: {
                            resource: function () {
                                return service.resource;
                            },
                            resourceFields: function () {
                                return service.resourceFields;
                            },
                            qAdv: function () {
                                return qAdv;
                            }
                        }
                    })
                    .then(function (updatedQAdv) {
                        // Store updated qAdv.
                        helpers.setLocalStorageItem('qAdv.' + service.resource, updatedQAdv);

                        // Pass current updated qAdv to the client code for searching.
                        return updatedQAdv;
                    });
                }

                if (!_.isEmpty(service.qAdvPreset)) {
                    // Preset qAdv is available, so prompt user to use it.
                    return $mdDialog.show(
                        $mdDialog.confirm()
                            .title('Would you like to use preset query?')
                            .textContent('Preset advanced query is available.')
                            .ok('Use preset query')
                            .cancel('Use last query')
                    ).then(function() {
                        // User chooses to use preset query
                        return show(getQAdvDefault());
                    }, function() {
                        // User chooses to use last query
                        return show(getStoredQAdv());
                    });
                } else {
                    // Preset qAdv is not available.
                    return show(getStoredQAdv());
                }
            }

            /**
             * Get qAdv default value.
             *
             * @returns {{s: {b: string, g: Array, i: number, lastI: number}, w: {b: string, g: Array, i: number, lastI: number}, h, o: {b: string, g: Array, i: number, lastI: number}}}
             */
            function getQAdvDefault() {
                return applyPresetQAdv({
                    s: {b: '', g: [], i: 0, lastI: 0},
                    w: {b: '', g: [], i: 0, lastI: 0},
                    h: (function () {
                        var whereHas = [];
                        angular.forEach(service.resourceFields, function (value) {
                            if (value.relation != 'this') {
                                whereHas.push({b: 'and', n: 0, r: value.relation, g: [], i: 0, lastI: 0});
                            }
                        });
                        return whereHas;
                    })(),
                    o: {b: '', g: [], i: 0, lastI: 0}
                });
            }

            /**
             * Extract qAdv items from their root node.
             * See "qAdv format" documentation at
             * advanced-search.controller.js
             *
             * @param qAdv
             * @returns {{}}
             */
            function extractQAdv(qAdv) {
                var extracted = {};
                if (angular.isDefined(qAdv.s)) extracted.s = getQAdvComponent(angular.copy(qAdv.s.g));
                if (angular.isDefined(qAdv.w)) extracted.w = getQAdvComponent(angular.copy(qAdv.w.g));
                if (angular.isDefined(qAdv.h)) extracted.h = getQAdvComponent(angular.copy(qAdv.h));
                if (angular.isDefined(qAdv.o)) extracted.o = getQAdvComponent(angular.copy(qAdv.o.g));
                return extracted;
            }

            /**
             * Reset AdvancedSearchFactory's settings.
             */
            function reset() {
                service.resource = null;
                service.resourceFields = [];
                service.qAdvPreset = {};
            }
        };

        // Return AdvancedSearchFactory provider object
        return {
            getInstance: function () {
                return new ServiceProvider();
            }
        };
    }
})();
