(function () {
    angular
        .module('app')
        .controller('ReaderController', ReaderController);

    // Define controller dependencies.
    ReaderController.$inject = [];

    /**
     * Controller for 'reader' state-view.
     *
     * @constructor
     */
    function ReaderController() {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
        }

        activate();
    }
})();
