(function () {
    'use strict';

    angular
        .module('app')
        .factory('JWTFactory', JWTFactory);

    // Define factory dependencies.
    JWTFactory.$inject = ['$location', '$mdDialog', '$q', '$rootScope', '$window', 'Restangular'];

    /**
     * Factory for authenticating with UAM server.
     *
     * @param $location
     * @param $mdDialog
     * @param $q
     * @param $rootScope
     * @param $window
     * @param Restangular
     * @constructor
     */
    function JWTFactory($location, $mdDialog, $q, $rootScope, $window, Restangular) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */
        var payload;

        /**
         * --------------------------------------------
         * Factory members.
         * --------------------------------------------
         */
        var service = {
            // Methods
            jwtCheck: jwtCheck,
            applyToken: applyToken,
            logout: logout
        };

        return service;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * Load UAM payload from URL query string.
         */
        function loadPayload() {
            if ($location.search().payload !== undefined) {
                payload = JSON.parse($location.search().payload);
            } else {
                payload = undefined;
            }
        }

        /**
         * Get user profile.
         *
         * @returns Promise
         */
        function getProfile() {
            var route = 'get-profile';
            return Restangular.oneUrl(route, $rootScope.global.uamUrl + '/api/' + route).get()
                .then(function (result) {
                    // Bring user info into scope
                    bringUserIntoScope(result.user);
                    // Bring API Partners info into scope
                    bringAPIPartnersIntoScope(result.APIPartners);
                })
        }

        /**
         * Refresh jwt token.
         *
         * @returns Promise
         */
        function refreshToken() {
            var route = 'refresh';
            return Restangular.oneUrl(route, $rootScope.global.uamUrl + '/api/' + route).get()
                .then(function (result) {
                    // Save retrieved token
                    helpers.setLocalStorageItem('token', result.token);
                    // Return new token
                    return result.original;
                });
        }

        /**
         * Get payload items.
         *
         * @param key
         * @returns {*}
         */
        function getPayloadItem(key) {
            return payload !== undefined ? payload[key] : undefined;
        }

        /**
         * Bring user into $rootScope.
         *
         * @param user
         */
        function bringUserIntoScope(user) {
            $rootScope.global.user = user;
        }

        /**
         * Bring API Partners into $rootScope.
         *
         * @param partners
         */
        function bringAPIPartnersIntoScope(partners) {
            $rootScope.global.APIPartners = partners;
        }

        /**
         * Check if a path is path without token authentication.
         *
         * @returns {*}
         */
        function pathWithoutToken() {
            // Path without token list.
            var paths = [
                    '/password-reset'
                ];
            var exists;

            angular.forEach(paths, function (value, key) {
                if (helpers.stringContains(value, $location.path()))
                    exists = true;
            });

            return exists;
        }

        /**
         * --------------------------------------------
         * Factory members implementations.
         * --------------------------------------------
         */

        /**
         * Check user's token whether valid or not.
         *
         * @returns {*}
         */
        function jwtCheck() {
            // Skip checking for certain paths.
            if (pathWithoutToken()) return $q.when();

            // Load UAM payload from URL query string.
            loadPayload();

            if (getPayloadItem('token') !== undefined) {
                // Query string token exists.

                if (helpers.getLocalStorageItem('token') !== null) {
                    // There is existing token in the browser.

                    // Notify user about existing token in the browser.
                    return $mdDialog.show({
                        templateUrl: 'shared/jwt/already-logged-in.view.html',
                        controller: ['$mdDialog',
                            function ($mdDialog) {
                                var vm = this;
                                vm.close = function () { $mdDialog.hide(); }
                            }
                        ],
                        controllerAs: 'vm',
                        clickOutsideToClose: false,
                        escapeToClose: false
                    }).then(refreshToken);
                } else {
                    // There is no existing token in the browser.

                    // Save retrieved token.
                    helpers.setLocalStorageItem('token', getPayloadItem('token'));

                    // Get user profile.
                    return getProfile();
                }
            } else {
                // Query string token does not exists, trying to refresh existing token.
                return refreshToken().then(getProfile);
            }
        }


        /**
         * Apply token to the 'Authorization' HTTP request header.
         *
         * @param config
         */
        function applyToken(config) {
            var localToken = helpers.getLocalStorageItem('token');

            if (localToken !== null) {
                config.headers['Authorization'] = 'Bearer ' + localToken;
            }
        }

        /**
         * Logout a user.
         */
        function logout() {
            var route = 'logout';
            Restangular.oneUrl(route, $rootScope.global.uamUrl + '/api/' + route).post()
                .then(function () {
                    // Clear all browser local storage.
                    localStorage.clear();
                    // Redirect user to apps.
                    $window.location.href = $rootScope.global.appsUrl;
                });
        }
    }
})();
