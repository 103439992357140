(function () {
    'use strict';

    angular
        .module('app')
        .controller('HomeController', HomeController);

    // Define controller dependencies.
    HomeController.$inject = ['$rootScope', 'Restangular'];

    /**
     * Controller for 'home' route.
     *
     * @param $rootScope
     * @param Restangular
     * @constructor
     */
    function HomeController($rootScope, Restangular) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.quote = undefined;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Get inspirational quote.
         */
        function getQuote() {
            var route = 'quote';
            Restangular.oneUrl(route, $rootScope.global.uamUrl + '/api/' + route).get().then(function (result) {
                vm.quote = result.original;
            });
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            $rootScope.global.pageTitle = 'Home';
            getQuote();
        }

        activate();
    }
})();
