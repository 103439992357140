(function () {
    'use strict';

    angular
        .module('app')
        .controller('MainController', MainController);

    // Define controller dependencies.
    MainController.$inject = ['$anchorScroll', '$location', '$mdDialog', '$mdMenu', '$mdSidenav', '$rootScope', '$scope', '$state', '$timeout', 'JWTFactory', 'ssSideNav', 'ssSideNavSections'];

    /**
     * Controller for main angular app.
     *
     * @param $anchorScroll
     * @param $location
     * @param $mdDialog
     * @param $mdMenu
     * @param $mdSidenav
     * @param $rootScope
     * @param $scope
     * @param $state
     * @param $timeout
     * @param JWTFactory
     * @param ssSideNav
     * @param ssSideNavSections
     * @constructor
     */
    function MainController($anchorScroll, $location, $mdDialog, $mdMenu, $mdSidenav, $rootScope, $scope, $state, $timeout, JWTFactory, ssSideNav, ssSideNavSections) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.sidenav = ssSideNav;

        /**
         * Bindable methods.
         */
        vm.isPasswordReset = isPasswordReset;
        vm.showUser = showUser;
        vm.showVersion = showVersion;
        vm.toggleSideNav = toggleSideNav;
        vm.isSideNavOpen = isSideNavOpen;
        vm.hideMenuBar = hideMenuBar;
        vm.logout = JWTFactory.logout;

        /**
         * Root level shared object that will
         * be accessible for all controllers.
         */
        $scope.shared = {
            /**
             * $state object used to switch between state.
             */
            $state: $state,
            /**
             * Scroll browser to specified element by id.
             *
             * @param id
             */
            scrollTo: function scrollTo(id) {
                $location.hash(id);
                $anchorScroll();
                $location.hash(null);
            }
        };

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Check if current state is password reset state.
         *
         * @returns {boolean}
         */
        function isPasswordReset() {
            return $state.current.name == 'password-reset';
        }

        /**
         * Show dialog displaying current login user info.
         */
        function showUser() {
            $mdDialog.show({
                controller: 'UserInfoController',
                controllerAs: 'vm',
                templateUrl: 'components/main/user-info.view.html',
                clickOutsideToClose: true,
                resolve: {
                    user: function () {
                        return $rootScope.global.user;
                    },
                    APIPartners: function () {
                        return $rootScope.global.APIPartners;
                    }
                }
            });
        }

        /**
         * Show dialog displaying current app info.
         */
        function showVersion() {
            $mdDialog.show({
                controller: 'VersionInfoController',
                controllerAs: 'vm',
                templateUrl: 'components/main/version-info.view.html',
                clickOutsideToClose: true,
                resolve: {
                    version: function () {
                        return $rootScope.global.version;
                    },
                    user: function () {
                        return $rootScope.global.user;
                    }
                }
            });
        }

        /**
         * Toggle side nav menu.
         */
        function toggleSideNav() {
            // We must use sidenav with id 'left' so
            // that ssSideNav does not throw error
            // because it is hardcoded as 'left'.
            $mdSidenav('left').toggle();
        }

        /**
         * Check if sidenav is open.
         */
        function isSideNavOpen() {
            return angular.element('#side-nav').length ? $mdSidenav('left').isOpen() : false;
        }

        /**
         * Hide the md-menubar manually. Used when a 'non-menu' md-menu is clicked.
         */
        function hideMenuBar() {
            // Hide the menu bar
            $mdMenu.hide();

            // Drop z-index of the #top-nav back to its original value.
            angular.element('#top-nav').removeClass('has-open-menu');
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Define disabled states for guest
            var allStates = _.pluck($state.get(), 'name');
            var disabledStatesForGuest = [];
            [].forEach(function (stateMask) {
                disabledStatesForGuest = disabledStatesForGuest.concat(_.filter(allStates, function (state) {
                    return state.indexOf(stateMask) === 0;
                }))
            });

            // We initialize active workgroup and storage only after initial jwt check.
            $scope.$on('app.booted', function () {
                // Initialize local storage variables. We
                // use browser local storage so that user
                // selections will be shared across tabs.
                if (angular.isDefined($rootScope.global.user) && angular.isObject($rootScope.global.user)) {
                    // activeWorkgroupIndex stores the $index of currently
                    // active workgroup of either main app or API partners.
                    // e.g. given activeWorkgroupIndex = {"1": 0, "2": 1};
                    //      -> the active workgroup of the main app with id of 1 is its first workgroup (index 0)
                    //      -> the active workgroup of an API partner with id of 2 is its second workgroup (index 1)
                    if (!angular.isObject(helpers.getLocalStorageItem('activeWorkgroupIndex'))) {
                        var activeWorkgroupIndex = {};

                        // Preset main app's active workgroup index to be the first option.
                        activeWorkgroupIndex[$rootScope.global.user.applications[0].id] = 0;

                        // Preset API partners' active workgroup index to be the first option.
                        if (angular.isDefined($rootScope.global.APIPartners)) {
                            angular.forEach($rootScope.global.APIPartners, function (APIPartner) {
                                activeWorkgroupIndex[APIPartner.applications[0].id] = 0;
                            });
                        }

                        // Save as local storage variable.
                        helpers.setLocalStorageItem('activeWorkgroupIndex', activeWorkgroupIndex);
                    }

                    // activeStorageIndex stores the $index of currently
                    // active storage of either main app or API partners.
                    // e.g. given activeStorageIndex = {"1": 0, "2": 1};
                    //      -> the active storage of the main app with id of 1 is its first storage (index 0)
                    //      -> the active storage of an API partner with id of 2 is its second storage (index 1)
                    if (!angular.isObject(helpers.getLocalStorageItem('activeStorageIndex'))) {
                        var activeStorageIndex = {};

                        // Preset main app's active storage index to be the first option.
                        activeStorageIndex[$rootScope.global.user.applications[0].id] = 0;

                        // Preset API partners' active storage index to be the first option.
                        if (angular.isDefined($rootScope.global.APIPartners)) {
                            angular.forEach($rootScope.global.APIPartners, function (APIPartner) {
                                activeStorageIndex[APIPartner.applications[0].id] = 0;
                            });
                        }

                        // Save as local storage variable.
                        helpers.setLocalStorageItem('activeStorageIndex', activeStorageIndex);
                    }

                    // filterWorkgroupIndexes stores the $index of selected
                    // workgroups for report filtering. The filter workgroup
                    // is only applicable for main app NOT API Partners.
                    // e.g. given filterWorkgroupIndexes = [0,1];
                    //      -> the selected filtering workgroups are the first and second workgroups of the main app (index 0 and 1).
                    if (!angular.isArray(helpers.getLocalStorageItem('filterWorkgroupIndexes'))) {
                        // Preset filtering workgroup to be the first option.
                        helpers.setLocalStorageItem('filterWorkgroupIndexes', [0]);
                    }

                    // Redirect guest to home state if disabled state is accessed via full page reload
                    if ($rootScope.global.isGuest() && _.indexOf(disabledStatesForGuest, $state.current.name) > -1) {
                        $state.go('home');
                    }
                }
            });

            $rootScope.$on('$stateChangeStart', function (event, toState) {
                // Prevent guest to access certain states
                if ($rootScope.global.isGuest() && _.indexOf(disabledStatesForGuest, toState.name) > -1) {
                    event.preventDefault();
                }
            });

            $rootScope.$on('$stateChangeSuccess', function (event, toState) {
                // Drop z-index of the md-menubar's md-toolbar
                // container (#top-nav) back to its original
                // value when nested menu item is clicked.
                angular.element('#top-nav').removeAttr('style');

                // When a user navigate to another resource via reader, he will be taken directly to '*.*.read'
                // state, whereas the sidenav pages are assigned with '*.*.data-list' state. Thus we will
                // manually select the corresponding page. This only applicable for CRUD based states.
                if (toState.name.split('.').length == 3) {
                    var sections = ssSideNavSections.sections;
                    var baseState = toState.name.split('.').slice(0,2).join('.');

                    sections.forEach(function(section) {
                        if (section.pages) {
                            section.pages.forEach(function(page) {
                                var pageBaseState = page.state.split('.').slice(0,2).join('.');
                                if (pageBaseState == baseState) {
                                    vm.sidenav.selectSection(section);
                                    vm.sidenav.selectPage(section, page);
                                }
                            });
                        }
                    });
                }
            });

            // Hide side menu nav's items if logged in user is guest
            $rootScope.$watch('global.user', function () {
                if ($rootScope.global.isGuest()) {
                    [].forEach(function (item) {
                        vm.sidenav.setVisible(item, false);
                    });
                }
            });

            $rootScope.$on('$mdMenuOpen', function () {
                angular.element('#top-nav').addClass('has-open-menu');
            });

            $rootScope.$on('$mdMenuClose', function () {
                angular.element('#top-nav').removeClass('has-open-menu');
            });
        }

        activate();
    }
})();
