(function () {
    angular
        .module('app')
        .factory('RelationFactory', RelationFactory);

    // Define factory dependencies.
    RelationFactory.$inject = ['$mdDialog', 'AdvancedSearchFactory', 'DataListFactory', 'LookupFactory', 'Restangular'];

    /**
     * Factory for registering relation.
     *
     * @param $mdDialog
     * @param AdvancedSearchFactory
     * @param DataListFactory
     * @param LookupFactory
     * @param Restangular
     * @constructor
     */
    function RelationFactory($mdDialog, AdvancedSearchFactory, DataListFactory, LookupFactory, Restangular) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members.
         * --------------------------------------------
         */

        var service = {
            // Properties.
            subject: {
                resource: null,
                relation: null,
                id: ''
            },
            resource: null,
            relation: null,
            lookupResourceFields: [],
            hDefault: {},

            // Methods.
            setSubject: setSubject,
            setRelation: setRelation,
            setRelationDataList: setRelationDataList,
            register: register,
            unregister: unregister,
            reset: reset
        };

        return service;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members implementations.
         * --------------------------------------------
         */

        /**
         * Set subject resource name and its belongs to many relation name.
         *
         * @param resource
         * @param relation
         * @param id
         */
        function setSubject(resource, relation, id) {
            service.subject.resource = resource;
            service.subject.relation = relation;
            service.subject.id = id;
        }

        /**
         * Set relation resource name and its belongs to many relation name.
         *
         * @param resource
         * @param relation
         */
        function setRelation(resource, relation) {
            service.resource = resource;
            service.relation = relation;
            service.hDefault = {b: 'and', n: 0, r: service.relation, g: [{b: 'and', f: 'id', o: '=', v: service.subject.id}]};
        }

        /**
         * Prepare relation's data list.
         */
        function setRelationDataList() {
            // Set data list resource name.
            DataListFactory.resource = service.resource;

            // Set default advanced search query.
            if (DataListFactory.qAdv == null) {
                DataListFactory.qAdv = AdvancedSearchFactory.getQAdvDefault();
            }

            // Set where has section of the query because we
            // only show the selected subject's relation by
            // filtering the relations based on subject id.
            DataListFactory.qAdv.h.push(service.hDefault);
        }

        /**
         * Register resources.
         */
        function register() {
            LookupFactory.show(
                service.resource,
                service.lookupResourceFields,
                function (selection) {
                    if (!angular.isDefined(selection) || selection === null) return;

                    DataListFactory.setBusy();
                    // Send register POST request to '{subject}/register/{relation}'
                    // URI with subject id and relation ids as the POST payload.
                    Restangular.one(service.subject.resource)
                        .customPOST(
                        (function () {
                            var ids = [];
                            selection.forEach(function (value, key) {
                                ids.push(value['id']);
                            });
                            return {
                                subject: service.subject.id,
                                relations: ids
                            };
                        })(),
                        'register/' + service.subject.relation
                    )
                        .then(function () {
                            DataListFactory.setNotBusy();
                            DataListFactory.search();
                        }, function () {
                            DataListFactory.setNotBusy();
                        });
                }
            );
        }

        /**
         * Unregister resources.
         */
        function unregister() {
            var selection = DataListFactory.gridApi.selection.getSelectedRows();

            if (selection.length > 0) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .title('Are you sure want to unregister selected items?')
                        .content(selection.length + ' items will be unregistered. All items will be permanently unregistered.')
                        .ok('Unregister')
                        .cancel('Cancel')
                )
                .then(function () {
                    DataListFactory.setBusy();
                    // Send unregister POST request to '{subject}/unregister/{relation}'
                    // URI with subject id and relation ids as the POST payload.
                    Restangular.one(service.subject.resource)
                        .customPOST(
                            (function () {
                                var ids = [];
                                selection.forEach(function (value, key) {
                                    ids.push(value['id']);
                                });
                                return {
                                    subject: service.subject.id,
                                    relations: ids
                                };
                            })(),
                            'unregister/' + service.subject.relation)
                        .then(function () {
                            DataListFactory.setNotBusy();
                            DataListFactory.search();
                        }, function () {
                            DataListFactory.setNotBusy();
                        });
                });
            }
        }

        /**
         * Reset relation factory.
         */
        function reset() {
            service.subject.resource = null;
            service.subject.relation = null;
            service.subject.id = '';
            service.resource = null;
            service.relation = null;
            service.lookupResourceFields = [];
            service.hDefault = {};
        }
    }
})();
