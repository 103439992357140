var helpers = new (function () {
    /**
     * Helper functions.
     */
    this.getMetaContent = getMetaContent;
    this.chooseFile = chooseFile;
    this.stringContains = stringContains;
    this.setLocalStorageItem = setLocalStorageItem;
    this.getLocalStorageItem = getLocalStorageItem;

    ////////////////////

    /**
     * Get meta header content using its name.
     *
     * @param name
     * @returns {*}
     */
    function getMetaContent(name) {
        var metas = document.getElementsByTagName('meta');
        return metas[name].content.trim();
    }

    /**
     * Prompt user to choose files using input file dialog.
     *
     * @param callback
     * @param accept
     * @param multiple
     */
    function chooseFile(callback, accept, multiple) {
        var chooser = document.getElementById('fileChooser');
        if (!chooser) {
            // Create input element.
            chooser = document.createElement('input');
            chooser.setAttribute('id', 'fileChooser');
            chooser.setAttribute('type', 'file');
            chooser.setAttribute('accept', accept);
            chooser.setAttribute('style', 'display: none');
            if (multiple)
                chooser.setAttribute('multiple', 'multiple');
            chooser.addEventListener("change", function (evt) {
                callback(evt.target.files);
            }, false);
            // Append input element.
            document.body.appendChild(chooser);
        }
        // Open input file dialog.
        chooser.click();
    }

    /**
     * Check if this string contains the given string.
     *
     * @param needle
     * @param haystack
     * @returns {boolean}
     */
    function stringContains(needle, haystack) {
        return haystack.indexOf(needle) !== -1;
    }

    /**
     * Set browser's local storage item.
     *
     * @param key
     * @param value
     */
    function setLocalStorageItem(key, value) {
        localStorage.setItem(key, angular.toJson(value, false));
    }

    /**
     * Get browser's local storage item.
     *
     * @param key
     * @returns {*|Object|Array|string|number}
     */
    function getLocalStorageItem(key) {
        try {
            return angular.fromJson(localStorage.getItem(key));
        } catch (e) {
            // Firefox throws error if the value is not
            // valid JSON, so we just return it as is.
            return localStorage.getItem(key);
        }
    }
});
