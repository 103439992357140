(function () {
    angular
        .module('app')
        .controller('UserRoleRelationController', UserRoleRelationController);

    // Define controller dependencies.
    UserRoleRelationController.$inject = ['$rootScope', '$scope', 'AdvancedSearchFactory', 'DataListFactory', 'RelationFactory'];

    /**
     * Controller for 'relation@register' view of 'register.user.role' state.
     *
     * @param $rootScope
     * @param $scope
     * @param AdvancedSearchFactory
     * @param DataListFactory
     * @param RelationFactory
     * @constructor
     */
    function UserRoleRelationController($rootScope, $scope, AdvancedSearchFactory, DataListFactory, RelationFactory) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        vm.factory = RelationFactory;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            var subject = 'user';
            var relationToRegister = 'role';

            if ( ! $scope.shared.entityExist) {
                $scope.shared.$state.go('register.' + subject);
                return;
            }

            DataListFactory.reset();
            DataListFactory.quickSearchDisabled = true;
            DataListFactory.gridOptions.columnDefs = (function () {
                var common = angular.copy($rootScope.global.columnDefs.commonRelation);
                angular.forEach(common, function (value, key) {
                    value.field = value.field.replace('@', _.pluralize(subject));
                });
                common.splice.apply(common, [1, 0].concat($scope.global.columnDefs[relationToRegister]));
                return common;
            })();

            DataListFactory.advancedSearchFactory.reset();
            DataListFactory.advancedSearchFactory.resource = relationToRegister;
            var resourceField = {
                relation: 'this',
                fields  : angular.copy($rootScope.global.resourceFields.common)
            }
            resourceField.fields.splice.apply(resourceField.fields,
                [1, 0].concat($rootScope.global.resourceFields[relationToRegister]));
            DataListFactory.advancedSearchFactory.resourceFields.push(resourceField);

            RelationFactory.reset();
            RelationFactory.setSubject(subject, _.pluralize(relationToRegister), $scope.shared.entity.id);
            RelationFactory.setRelation(relationToRegister, _.pluralize(subject));
            RelationFactory.setRelationDataList();
            angular.forEach($rootScope.global.searchResourceFields[relationToRegister], function (relation) {
                var resourceField = {
                    relation: relation.relation,
                    fields  : angular.copy($rootScope.global.resourceFields.common)
                }
                resourceField.fields.splice.apply(resourceField.fields,
                    [1, 0].concat($rootScope.global.resourceFields[relation.resource]));
                RelationFactory.lookupResourceFields.push(resourceField);
            });
        }

        activate();
    }
})();
