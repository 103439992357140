(function () {
    angular
        .module('app')
        .controller('RoleEditorController', RoleEditorController);

    // Define controller dependencies.
    RoleEditorController.$inject = ['$q', '$rootScope', '$scope', 'Restangular', 'SanitizerFactory'];

    /**
     * Controller for 'editor' view of 'master.role' state.
     *
     * @param $q
     * @param $rootScope
     * @param $scope
     * @param Restangular
     * @param SanitizerFactory
     * @constructor
     */
    function RoleEditorController($q, $rootScope, $scope, Restangular, SanitizerFactory) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.applicationValues = undefined;
        vm.administratorValues = undefined;
        vm.guestValues = undefined;
        vm.activeValues = undefined;

        /**
         * Bindable methods.
         */
        vm.setApplicationValues = setApplicationValues;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * Initialize editor's data source.
         *
         * @returns Promise
         */
        function initialize() {
            $scope.shared.editor.initialized = false;

            vm.applicationValues = undefined;
            vm.activeValues = undefined;

            return $q.when()
                .then(setAdministratorValues)
                .then(setGuestValues)
                .then(setActiveValues)
                .then(function () {
                    $scope.shared.editor.initialized = true;
                })
                .catch(function () {
                    return $q.reject();
                });

            function getResourceFieldValues(field) {
                return _.get(_.find($rootScope.global.resourceFields.role, {'name': field}), 'values');
            }

            function setAdministratorValues() {
                vm.administratorValues = getResourceFieldValues('administrator');
                return $q.when();
            }

            function setGuestValues() {
                vm.guestValues = getResourceFieldValues('guest');
                return $q.when();
            }

            function setActiveValues() {
                vm.activeValues = getResourceFieldValues('active');
                return $q.when();
            }
        }

        /**
         * Set default value for new resource.
         */
        function setNew() {
            if ($scope.shared.editor.initialized) {
                $scope.shared.editor.entity = {
                    id: undefined,
                    application_id: '',
                    name: '',
                    administrator: vm.administratorValues[0].value,
                    guest: vm.guestValues[0].value,
                    active: vm.activeValues[1].value
                };
            }
        }

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Set application values.
         */
        function setApplicationValues() {
            vm.applicationValues = undefined;
            return Restangular.all('application').getList({q: {
                s: [{f: 'id'}, {f: 'code'}],
                o: [{f: 'code', o: 'asc'}]
            }})
                .then(function (result) {
                    vm.applicationValues = SanitizerFactory.sanitizeReceived(result.original);
                });
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            $scope.shared.editor.initialize = initialize;
            $scope.shared.editor.setNew = setNew;

            $scope.$on('CRUDFactory.editSuccess', function (event, entity) {
                vm.applicationValues = [entity.application];
            });
        }

        activate();
    }
})();
