(function () {
    'use strict';

    angular
        .module('app')
        .controller('VersionInfoController', VersionInfoController);

    // Define controller dependencies.
    VersionInfoController.$inject = ['$mdDialog', 'version', 'user'];

    /**
     * Controller for version info dialog.
     *
     * @param $mdDialog
     * @param version
     * @param user
     * @constructor
     */
    function VersionInfoController($mdDialog, version, user) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.version = version;
        vm.user = user;

        /**
         * Bindable methods.
         */
        vm.close = close;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Close dialog.
         */
        function close() {
            $mdDialog.hide();
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {

        }

        activate();
    }
})();
