/**
 * Created by DAVIDHIU on 02/13/2015.
 */

(function () {
    angular
        .module('app')
        .service('FocusService', FocusService);

    FocusService.$inject = ['$timeout'];

    /* @ngInject */
    function FocusService($timeout) {
        this.focus = function (selector, timeout) {
            if (isMobile) {
                return;
            }

            // timeout makes sure that it is invoked after any other event has been triggered.
            // e.g. click events that need to run before the focus or
            // inputs elements that are in a disabled state but are enabled when those events
            // are triggered.
            $timeout(function () {
                var element = document.querySelector(selector);
                if (element)
                    element.focus();
            }, timeout);
        };
    }
})();
