(function () {
    'use strict';

    angular
        .module('app')
        .config(route);

    // Define route dependencies.
    route.$inject = ['$locationProvider', '$stateProvider', '$urlRouterProvider'];

    /**
     * Define app routes.
     *
     * @param $locationProvider
     * @param $stateProvider
     * @param $urlRouterProvider
     */
    function route($locationProvider, $stateProvider, $urlRouterProvider) {
        // Use html5 mode.
        $locationProvider.html5Mode(true);

        // Define default route.
        $urlRouterProvider.otherwise('/home');

        // Define app routes using ui-router state.
        $stateProvider
            // Home state.
            .state('home', {
                url: '/home',
                templateUrl : 'components/home/home.view.html',
                controller  : 'HomeController',
                controllerAs: 'vm'
            })

            /////////////////////

            // Master abstract state.
            .state('master', {
                abstract: true,
                url: '/master',
                templateUrl : 'shared/crud/crud.view.html',
                controller  : 'CRUDController',
                controllerAs: 'vm'
            })

            // Master user states.
            .state('master.user', {
                abstract: true,
                url: '/user',
                views: {
                    'main': {
                        controller  : 'UserController',
                        controllerAs: 'vm'
                    },
                    'data-list': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                    'editor': {
                        templateUrl : 'shared/crud/editor/editor.view.html',
                        controller  : 'UserEditorController',
                        controllerAs: 'vm'
                    },
                    'reader': {
                        templateUrl : 'shared/crud/reader/reader.view.html',
                        controller  : 'ReaderController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('master.user.data-list', {
                url: ''
            })
            .state('master.user.create', {
                url: '/create'
            })
            .state('master.user.read', {
                url: '/:id'
            })
            .state('master.user.update', {
                url: '/:id/update'
            })

            // Master application states.
            .state('master.application', {
                abstract: true,
                url: '/application',
                views: {
                    'main': {
                        controller  : 'ApplicationController',
                        controllerAs: 'vm'
                    },
                    'data-list': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                    'actions': {
                        templateUrl : 'components/master/application/application-actions.view.html',
                        controller  : 'ApplicationActionsController',
                        controllerAs: 'vm'
                    },
                    'editor': {
                        templateUrl : 'shared/crud/editor/editor.view.html',
                        controller  : 'ApplicationEditorController',
                        controllerAs: 'vm'
                    },
                    'reader': {
                        templateUrl : 'shared/crud/reader/reader.view.html',
                        controller  : 'ReaderController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('master.application.data-list', {
                url: ''
            })
            .state('master.application.create', {
                url: '/create'
            })
            .state('master.application.read', {
                url: '/:id'
            })
            .state('master.application.update', {
                url: '/:id/update'
            })

            // Master version states.
            .state('master.version', {
                abstract: true,
                url: '/version',
                views: {
                    'main': {
                        controller  : 'VersionController',
                        controllerAs: 'vm'
                    },
                    'data-list': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                    'editor': {
                        templateUrl : 'shared/crud/editor/editor.view.html',
                        controller  : 'VersionEditorController',
                        controllerAs: 'vm'
                    },
                    'reader': {
                        templateUrl : 'shared/crud/reader/reader.view.html',
                        controller  : 'ReaderController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('master.version.data-list', {
                url: ''
            })
            .state('master.version.create', {
                url: '/create'
            })
            .state('master.version.read', {
                url: '/:id'
            })
            .state('master.version.update', {
                url: '/:id/update'
            })

            // Master role states.
            .state('master.role', {
                abstract: true,
                url: '/role',
                views: {
                    'main': {
                        controller  : 'RoleController',
                        controllerAs: 'vm'
                    },
                    'data-list': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                    'editor': {
                        templateUrl : 'shared/crud/editor/editor.view.html',
                        controller  : 'RoleEditorController',
                        controllerAs: 'vm'
                    },
                    'reader': {
                        templateUrl : 'shared/crud/reader/reader.view.html',
                        controller  : 'ReaderController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('master.role.data-list', {
                url: ''
            })
            .state('master.role.create', {
                url: '/create'
            })
            .state('master.role.read', {
                url: '/:id'
            })
            .state('master.role.update', {
                url: '/:id/update'
            })

            // Master permission states.
            .state('master.permission', {
                abstract: true,
                url: '/permission',
                views: {
                    'main': {
                        controller  : 'PermissionController',
                        controllerAs: 'vm'
                    },
                    'data-list': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                    'editor': {
                        templateUrl : 'shared/crud/editor/editor.view.html',
                        controller  : 'PermissionEditorController',
                        controllerAs: 'vm'
                    },
                    'reader': {
                        templateUrl : 'shared/crud/reader/reader.view.html',
                        controller  : 'ReaderController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('master.permission.data-list', {
                url: ''
            })
            .state('master.permission.create', {
                url: '/create'
            })
            .state('master.permission.read', {
                url: '/:id'
            })
            .state('master.permission.update', {
                url: '/:id/update'
            })

            // Master workgroup states.
            .state('master.workgroup', {
                abstract: true,
                url: '/workgroup',
                views: {
                    'main': {
                        controller  : 'WorkgroupController',
                        controllerAs: 'vm'
                    },
                    'data-list': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                    'editor': {
                        templateUrl : 'shared/crud/editor/editor.view.html',
                        controller  : 'WorkgroupEditorController',
                        controllerAs: 'vm'
                    },
                    'reader': {
                        templateUrl : 'shared/crud/reader/reader.view.html',
                        controller  : 'ReaderController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('master.workgroup.data-list', {
                url: ''
            })
            .state('master.workgroup.create', {
                url: '/create'
            })
            .state('master.workgroup.read', {
                url: '/:id'
            })
            .state('master.workgroup.update', {
                url: '/:id/update'
            })

            // Master storage states.
            .state('master.storage', {
                abstract: true,
                url: '/storage',
                views: {
                    'main': {
                        controller  : 'StorageController',
                        controllerAs: 'vm'
                    },
                    'data-list': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                    'editor': {
                        templateUrl : 'shared/crud/editor/editor.view.html',
                        controller  : 'StorageEditorController',
                        controllerAs: 'vm'
                    },
                    'reader': {
                        templateUrl : 'shared/crud/reader/reader.view.html',
                        controller  : 'ReaderController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('master.storage.data-list', {
                url: ''
            })
            .state('master.storage.create', {
                url: '/create'
            })
            .state('master.storage.read', {
                url: '/:id'
            })
            .state('master.storage.update', {
                url: '/:id/update'
            })

            /////////////////////

            // Register abstract state.
            .state('register', {
                abstract: true,
                url: '/register',
                templateUrl : 'shared/register/register.view.html',
                controller  : 'RegisterController',
                controllerAs: 'vm'
            })

            // Register role states.
            .state('register.role', {
                url     : '/role',
                views   : {
                    'main': {
                        controller  : 'RegisterRoleController',
                        controllerAs: 'vm'
                    },
                    'subject': {
                        templateUrl : 'shared/register/subject.view.html'
                    }
                }
            })
            .state('register.role.permission', {
                url     : '/permission',
                views   : {
                    'main-relation@register.role.permission': {
                        controller  : 'RelationController',
                        controllerAs: 'vm'
                    },
                    'relation@register': {
                        templateUrl : 'shared/register/relation.view.html',
                        controller  : 'RolePermissionRelationController',
                        controllerAs: 'vm'
                    },
                    'data-list@register.role.permission': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                }
            })

            // Register workgroup states.
            .state('register.workgroup', {
                url     : '/workgroup',
                views   : {
                    'main': {
                        controller  : 'RegisterWorkgroupController',
                        controllerAs: 'vm'
                    },
                    'subject': {
                        templateUrl : 'shared/register/subject.view.html'
                    }
                }
            })
            .state('register.workgroup.storage', {
                url     : '/storage',
                views   : {
                    'main-relation@register.workgroup.storage': {
                        controller  : 'RelationController',
                        controllerAs: 'vm'
                    },
                    'relation@register': {
                        templateUrl : 'shared/register/relation.view.html',
                        controller  : 'WorkgroupStorageRelationController',
                        controllerAs: 'vm'
                    },
                    'data-list@register.workgroup.storage': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    },
                }
            })

            // Register user states.
            .state('register.user', {
                url     : '/user',
                views   : {
                    'main': {
                        controller  : 'RegisterUserController',
                        controllerAs: 'vm'
                    },
                    'subject': {
                        templateUrl : 'shared/register/subject.view.html'
                    }
                }
            })
            .state('register.user.application', {
                url     : '/application',
                views   : {
                    'main-relation@register.user.application': {
                        controller  : 'RelationController',
                        controllerAs: 'vm'
                    },
                    'relation@register': {
                        templateUrl : 'shared/register/relation.view.html',
                        controller  : 'UserApplicationRelationController',
                        controllerAs: 'vm'
                    },
                    'data-list@register.user.application': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('register.user.role', {
                url     : '/role',
                views   : {
                    'main-relation@register.user.role': {
                        controller  : 'RelationController',
                        controllerAs: 'vm'
                    },
                    'relation@register': {
                        templateUrl : 'shared/register/relation.view.html',
                        controller  : 'UserRoleRelationController',
                        controllerAs: 'vm'
                    },
                    'data-list@register.user.role': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('register.user.permission', {
                url     : '/permission',
                views   : {
                    'main-relation@register.user.permission': {
                        controller  : 'RelationController',
                        controllerAs: 'vm'
                    },
                    'relation@register': {
                        templateUrl : 'shared/register/relation.view.html',
                        controller  : 'UserPermissionRelationController',
                        controllerAs: 'vm'
                    },
                    'data-list@register.user.permission': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('register.user.workgroup', {
                url     : '/workgroup',
                views   : {
                    'main-relation@register.user.workgroup': {
                        controller  : 'RelationController',
                        controllerAs: 'vm'
                    },
                    'relation@register': {
                        templateUrl : 'shared/register/relation.view.html',
                        controller  : 'UserWorkgroupRelationController',
                        controllerAs: 'vm'
                    },
                    'data-list@register.user.workgroup': {
                        templateUrl : 'shared/data-list/data-list.view.html',
                        controller  : 'DataListController',
                        controllerAs: 'vm'
                    }
                }
            })

            /////////////////////

            // Report abstract state.
            .state('report', {
                abstract: true,
                url: '/report',
                templateUrl : 'shared/report/report.view.html',
                controller  : 'ReportController',
                controllerAs: 'vm'
            })

            // Report user state.
            .state('report.user', {
                url     : '/user',
                views    : {
                    'main': {
                        controller  : 'UserReportController',
                        controllerAs: 'vm'
                    }
                }
            })

            // Report application state.
            .state('report.application', {
                url     : '/application',
                views    : {
                    'main': {
                        controller  : 'ApplicationReportController',
                        controllerAs: 'vm'
                    }
                }
            })

            // Report version state.
            .state('report.version', {
                url     : '/version',
                views    : {
                    'main': {
                        controller  : 'VersionReportController',
                        controllerAs: 'vm'
                    }
                }
            })

            // Report role state.
            .state('report.role', {
                url     : '/role',
                views    : {
                    'main': {
                        controller  : 'RoleReportController',
                        controllerAs: 'vm'
                    }
                }
            })

            // Report permission state.
            .state('report.permission', {
                url     : '/permission',
                views    : {
                    'main': {
                        controller  : 'PermissionReportController',
                        controllerAs: 'vm'
                    }
                }
            })

            // Report workgroup state.
            .state('report.workgroup', {
                url     : '/workgroup',
                views    : {
                    'main': {
                        controller  : 'WorkgroupReportController',
                        controllerAs: 'vm'
                    }
                }
            })

            // Report storage state.
            .state('report.storage', {
                url     : '/storage',
                views    : {
                    'main': {
                        controller  : 'StorageReportController',
                        controllerAs: 'vm'
                    }
                }
            })

            /////////////////////

            // Password reset state.
            .state('password-reset', {
                url: '/password-reset/:token',
                templateUrl : 'components/password/reset.view.html',
                controller  : 'ResetController',
                controllerAs: 'vm'
            });
    }
})();
