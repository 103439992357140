(function () {
    angular
        .module('app')
        .controller('ApplicationActionsController', ApplicationActionsController);

    // Define controller dependencies.
    ApplicationActionsController.$inject = ['$mdDialog', 'DataListFactory', 'Restangular'];

    /**
     * Controller for 'actions' view of 'master.application' state.
     *
     * @param $mdDialog
     * @param DataListFactory
     * @param Restangular
     * @constructor
     */
    function ApplicationActionsController($mdDialog, DataListFactory, Restangular) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Methods.
         */
        vm.relogin = relogin;
        vm.syncPermissions = syncPermissions;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Force all currently logged in users of the
         * selected application to login in again.
         */
        function relogin() {
            var selection = DataListFactory.gridApi.selection.getSelectedRows();

            if (selection.length == 1) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .title('Are you sure want to relogin?')
                        .content('All users currently logged in to the selected application will be forced to login again.')
                        .ok('Yes')
                        .cancel('No')
                ).then(function () {
                    DataListFactory.setBusy();
                    Restangular.restangularizeElement('', selection[0], 'application')
                        .customPUT({}, 'relogin')
                        .finally(function () {
                            DataListFactory.setNotBusy();
                        });
                });
            }
        }

        /**
         * Sync all current available permissions
         * of the selected application into UAM.
         */
        function syncPermissions() {
            var selection = DataListFactory.gridApi.selection.getSelectedRows();

            if (selection.length == 1) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .title('Are you sure want to sync permissions?')
                        .content('UAM will ask the selected application\'s API a list of all current available permissions and sync it to UAM.')
                        .ok('Yes')
                        .cancel('No')
                ).then(function () {
                    DataListFactory.setBusy();
                    Restangular.restangularizeElement('', selection[0], 'application')
                        .customPUT({}, 'sync-permission')
                        .finally(function () {
                            DataListFactory.setNotBusy();
                        });
                });
            }
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
        }

        activate();
    }
})();
