(function () {
    angular
        .module('app')
        .controller('RegisterController', RegisterController);

    // Define controller dependencies.
    RegisterController.$inject = ['$q', '$scope', 'DataListFactory', 'FocusService', 'hotkeys', 'Restangular'];

    /**
     * Controller for 'register' abstract state.
     *
     * @param $q
     * @param $scope
     * @param DataListFactory
     * @param FocusService
     * @param hotkeys
     * @param Restangular
     * @constructor
     */
    function RegisterController($q, $scope, DataListFactory, FocusService, hotkeys, Restangular) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * Register controller default settings
         */
        var sharedDefault = {
            resource: '',
            mainRegisterState: '',
            q: null,
            entity: {},
            subjectTemplate: '',
            entityExist: false,
            loading: false,
            reset: reset
        };

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.searchForm = undefined;

        /**
         * Bindable methods.
         */
        vm.search = search;

        /**
         * Register Controller level shared object that extends
         * its parent controller (MainController) and will
         * be accessible for all of its child controllers.
         */
        $scope.shared = angular.extend(
            // New shared object.
            {},
            // Shared property from parent controller (MainController).
            $scope.shared,
            // This controller shared property.
            angular.copy(sharedDefault)
        );

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * Reset register controller.
         */
        function reset() {
            $scope.shared.resource = '';
            $scope.shared.mainRegisterState = '';
            $scope.shared.q = null;
            $scope.shared.entity = {};
            $scope.shared.subjectTemplate = '';
            $scope.shared.entityExist = false;
            if (vm.searchForm) {
                vm.searchForm.$setPristine();
            }
        }

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Search a subject resource to register.
         *
         * @returns Promise
         */
        function search() {
            // Go to main register state for this resource.
            $scope.shared.$state.go($scope.shared.mainRegisterState);

            $scope.shared.loading = true;
            // Clear subject resource entity.
            $scope.shared.entity = {};
            $scope.shared.entityExist = false;

            return getResource()
                .finally(function () {
                    $scope.shared.loading = false;
                });

            /**
             * Get a subject resource to register.
             *
             * @returns Promise
             */
            function getResource() {
                return Restangular.one($scope.shared.resource).get({
                    q   : $scope.shared.q,
                    d   : true,
                    skip: 0,
                    take: 1
                })
                    .then(function (result) {
                        if (result.original) {
                            $scope.shared.entity = result.original[0];
                            $scope.shared.entityExist = true;
                        } else {
                            return $q.reject('empty result');
                        }
                    }, function () {
                        return $q.reject('bad response');
                    })
            }
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Set focus to quick search input on state changed.
            $scope.$on('$stateChangeSuccess',
                function(event, toState, toParams, fromState, fromParams) {
                    if (toState.name.split('.').length == 2) {
                        FocusService.focus('#subject-quick-search');
                    }
                }
            );

            // Binding keyboard shortcuts.
            hotkeys.bindTo($scope)
                .add({
                    combo: 'ctrl+alt+j',
                    description: 'Search subject resource',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () {
                        FocusService.focus('#subject-quick-search');
                    }
                });
        }

        activate();
    }
})();
