(function () {
    angular
        .module('app')
        .controller('ApplicationController', ApplicationController);

    // Define controller dependencies.
    ApplicationController.$inject = ['$rootScope', '$scope', 'CRUDFactory', 'DataListFactory'];

    /**
     * Controller for 'main' view of 'master.application' state.
     *
     * @param $rootScope
     * @param $scope
     * @param CRUDFactory
     * @param DataListFactory
     * @constructor
     */
    function ApplicationController($rootScope, $scope, CRUDFactory, DataListFactory) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            var resource = 'application';

            $rootScope.global.pageTitle = 'Master Application';

            $scope.shared.printDisabled = true;
            $scope.shared.editor.template = 'components/master/application/application-editor.view.html';
            $scope.shared.editor.focusElement = '#application--code';
            $scope.shared.reader.template = 'components/master/application/application-reader.view.html';

            DataListFactory.reset();
            DataListFactory.resource = resource;
            DataListFactory.gridOptions.columnDefs = angular.copy($rootScope.global.columnDefs.common);
            DataListFactory.gridOptions.columnDefs.splice.apply(DataListFactory.gridOptions.columnDefs,
                [1, 0].concat($rootScope.global.columnDefs[resource]));

            DataListFactory.advancedSearchFactory.reset();
            DataListFactory.advancedSearchFactory.resource = resource;
            angular.forEach($rootScope.global.searchResourceFields[resource], function (relation) {
                var resourceField = {
                    relation: relation.relation,
                    fields  : angular.copy($rootScope.global.resourceFields.common)
                }
                resourceField.fields.splice.apply(resourceField.fields,
                    [1, 0].concat($rootScope.global.resourceFields[relation.resource]));
                DataListFactory.advancedSearchFactory.resourceFields.push(resourceField);
            });

            CRUDFactory.resource = resource;
        }

        activate();
    }
})();
