(function () {
    'use strict';

    angular
        .module('app')
        .factory('SanitizerFactory', SanitizerFactory);

    // Define factory dependencies.
    SanitizerFactory.$inject = [];

    /**
     * Factory to sanitize data received and sent.
     *
     * @param
     * @constructor
     */
    function SanitizerFactory() {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members.
         * --------------------------------------------
         */
        var service = {
            sanitizeSent: sanitizeSent,
            sanitizeReceived: sanitizeReceived
        };

        return service;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * Convert date of Date type to String type.
         *
         * @param value
         * @returns {*}
         */
        function dateToString(value) {
            if (value instanceof Date) {
                value = moment(value);
            }
            if (moment.isMoment(value)) {
                return value.format('YYYY-MM-DD HH:mm:ss.000');
            }
            return value;
        }

        /**
         * Convert date of String type to Date type.
         *
         * @param value
         * @returns {*}
         */
        function stringToDate(value) {
            var momentDate = moment(value, 'YYYY-MM-DD HH:mm:ss.000', true);
            if (momentDate.isValid()) {
                return momentDate.toDate();
            }
            return value;
        }

        /**
         * Convert undefined or empty string to null.
         *
         * @param value
         */
        function undefinedOrEmptyStringToNull(value) {
            return (value === undefined || value === '') ? null : value;
        }

        /**
         * --------------------------------------------
         * Factory members implementations.
         * --------------------------------------------
         */

        /**
         * Sanitize entity before being sent for saving.
         *
         * @param entity
         * @returns {*}
         */
        function sanitizeSent(entity) {
            if (entity instanceof Array) {
                return entity.map(function (value) {
                    return sanitizeSent(value);
                });
            } else if (entity instanceof Object && ! (entity instanceof Date)) {
                return _.mapValues(entity, function (value) {
                    return sanitizeSent(value);
                });
            } else {
                var sanitized = entity;
                sanitized = dateToString(sanitized);
                sanitized = undefinedOrEmptyStringToNull(sanitized);
                return sanitized;
            }
        }

        /**
         * Sanitize entity before being used for editing.
         *
         * @param entity
         * @returns {*}
         */
        function sanitizeReceived(entity) {
            if (entity instanceof Array) {
                return entity.map(function (value) {
                    return sanitizeReceived(value);
                });
            } else if (entity instanceof Object && ! (entity instanceof Date)) {
                return _.mapValues(entity, function (value) {
                    return sanitizeReceived(value);
                });
            } else {
                var sanitized = entity;
                sanitized = stringToDate(sanitized);
                return sanitized;
            }
        }
    }

})();
