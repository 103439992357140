(function () {
    angular
        .module('app')
        .controller('RelationController', RelationController);

    // Define controller dependencies.
    RelationController.$inject = ['$scope', 'hotkeys', 'RelationFactory'];

    /**
     * Controller for 'main-relation' view.
     *
     * @param $scope
     * @param hotkeys
     * @param RelationFactory
     * @constructor
     */
    function RelationController($scope, hotkeys, RelationFactory) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Binding keyboard shortcuts.
            hotkeys.bindTo($scope)
                .add({
                    combo: 'ctrl+alt+i',
                    description: 'Register resources',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { RelationFactory.register(); }
                })
                .add({
                    combo: 'shift+ctrl+alt+i',
                    description: 'Unregister resources',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { RelationFactory.unregister(); }
                });
        }

        activate();
    }
})();
