(function () {
    'use strict';

    angular
        .module('app')
        .factory('LookupFactory', LookupFactory);

    // Define factory dependencies.
    LookupFactory.$inject = ['$mdDialog', '$rootScope', '$timeout', 'DataListFactoryProvider'];

    /**
     * Factory for displaying lookup resource dialog.
     *
     * @param $mdDialog
     * @param $rootScope
     * @param $timeout
     * @param DataListFactoryProvider
     * @constructor
     */
    function LookupFactory($mdDialog, $rootScope, $timeout, DataListFactoryProvider) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */
        // Store last instance of DataListFactory.
        var lookupDataListFactory = null;

        /**
         * --------------------------------------------
         * Factory members.
         * --------------------------------------------
         */
        var service = {
            // Methods.
            show: show
        };

        return service;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members implementations.
         * --------------------------------------------
         */

        /**
         * Show lookup resource dialog.
         *
         * @param resource
         * @param resourceFields
         * @param onClosed
         * @param isSelectable
         * @param qAdvPreset
         * @param useLastDataList
         */
        function show(resource, resourceFields, onClosed, isSelectable, qAdvPreset, useLastDataList) {
            $mdDialog.show({
                templateUrl: 'shared/lookup/lookup.view.html',
                controller: 'LookupController',
                controllerAs: 'vm',
                clickOutsideToClose: true,
                resolve: {
                    lookupDataListFactory: function () {
                        // New instance required.
                        if (useLastDataList !== true || lookupDataListFactory === null) {
                            // Create new instance of DataListFactory.
                            lookupDataListFactory = DataListFactoryProvider.getInstance();

                            // Configure DataListFactory.
                            lookupDataListFactory.reset();
                            lookupDataListFactory.resource = resource;
                            lookupDataListFactory.gridOptions.columnDefs = angular.copy($rootScope.global.columnDefs.common);
                            lookupDataListFactory.gridOptions.columnDefs.splice.apply(lookupDataListFactory.gridOptions.columnDefs,
                                [1, 0].concat($rootScope.global.columnDefs[resource]));
                            lookupDataListFactory.contextSelector = 'md-dialog';
                            lookupDataListFactory.advancedSearchDialogClosedCallback = function () {
                                service.show(resource, resourceFields, onClosed, isSelectable, qAdvPreset, true);
                            };

                            // Configure AdvancedSearchFactory.
                            lookupDataListFactory.advancedSearchFactory.reset();
                            lookupDataListFactory.advancedSearchFactory.resource = resource;
                            lookupDataListFactory.advancedSearchFactory.resourceFields = resourceFields;
                            lookupDataListFactory.advancedSearchFactory.qAdvPreset = qAdvPreset || {};
                        }

                        return lookupDataListFactory;
                    },
                    isSelectable: function () {
                        return isSelectable;
                    }
                }
            })
            .then(function (selection) {
                // User selects or clears.
                if (angular.isDefined(selection)) {
                    // Call the callback function passing the selection.
                    onClosed(selection);
                }
            }, function () {
                // User close the dialog.
                // Call the callback function passing undefined.
                onClosed(undefined);
            });
        }
    }
})();
