(function () {
    angular
        .module('app')
        .controller('RegisterWorkgroupController', RegisterWorkgroupController);

    // Define controller dependencies.
    RegisterWorkgroupController.$inject = ['$rootScope', '$scope'];

    /**
     * Controller for 'main' view of 'register.workgroup' state.
     *
     * @param $rootScope
     * @param $scope
     * @constructor
     */
    function RegisterWorkgroupController($rootScope, $scope) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            $rootScope.global.pageTitle = 'Register Workgroup';

            $scope.shared.reset();
            $scope.shared.resource = 'workgroup';
            $scope.shared.mainRegisterState = 'register.workgroup';
            $scope.shared.subjectTemplate = 'components/register/workgroup/workgroup-subject.view.html';
        }

        activate();
    }
})();
