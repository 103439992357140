(function () {
    angular
        .module('app')
        .controller('RegisterUserController', RegisterUserController);

    // Define controller dependencies.
    RegisterUserController.$inject = ['$rootScope', '$scope'];

    /**
     * Controller for 'main' view of 'register.user' state.
     *
     * @param $rootScope
     * @param $scope
     * @constructor
     */
    function RegisterUserController($rootScope, $scope) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            $rootScope.global.pageTitle = 'Register User';

            $scope.shared.reset();
            $scope.shared.resource = 'user';
            $scope.shared.mainRegisterState = 'register.user';
            $scope.shared.subjectTemplate = 'components/register/user/user-subject.view.html';
        }

        activate();
    }
})();
