(function () {
    'use strict';

    angular
        .module('app')
        .factory('InfoMessageHandlerFactory', InfoMessageHandlerFactory);

    // Define factory dependencies.
    InfoMessageHandlerFactory.$inject = ['$mdToast', '$rootScope', ];

    /**
     * Factory for info message handling.
     *
     * @param $mdToast
     * @param $rootScope
     * @returns {{handle: handle}}
     * @constructor
     */
    function InfoMessageHandlerFactory($mdToast, $rootScope) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members.
         * --------------------------------------------
         */
        var service = {
            // Methods.
            handle: handle
        };

        return service;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Factory members implementations.
         * --------------------------------------------
         */

        /**
         * Handle info message.
         *
         * @param response
         */
        function handle(response) {
            // Get error message if response is Blob.
            if (response.data instanceof Blob) {
                var reader = new FileReader();
                reader.addEventListener("loadend", function() {
                    var clone = angular.copy(response);
                    clone.data = JSON.parse(reader.result);
                    $rootScope.$apply(function () {
                        handle(response);
                    });
                });
                reader.readAsText(response.data);
                return;
            }

            if (response.data.message) {
                // Build inofo message.
                var info = {
                    status: response.status,
                    statusText: response.statusText,
                    message: response.data.message
                };

                $mdToast.show({
                    controller: ['info', function (info) {
                        var vm = this;
                        vm.info = info;
                    }],
                    controllerAs: 'vm',
                    templateUrl: 'shared/message-handler/info-message.view.html',
                    parent : angular.element('body'),
                    hideDelay: 3000,
                    position: 'bottom left',
                    resolve: {
                        info: function () {
                            return info;
                        }
                    }
                });
            }
        }
    }
})();
