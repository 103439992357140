(function () {
    'use strict';

    angular
        .module('app')
        .controller('UserEditorController', UserEditorController);

    // Define controller dependencies.
    UserEditorController.$inject = ['$q', '$rootScope', '$scope'];

    /**
     * Controller for 'editor' view of 'master.user' state.
     *
     * @param $q
     * @param $rootScope
     * @param $scope
     * @constructor
     */
    function UserEditorController($q, $rootScope, $scope) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.activeValues = undefined;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * Initialize editor's data source.
         *
         * @returns Promise
         */
        function initialize() {
            $scope.shared.editor.initialized = false;

            vm.activeValues = undefined;

            return $q.when()
                .then(setActiveValues)
                .then(function () {
                    $scope.shared.editor.initialized = true;
                })
                .catch(function () {
                    return $q.reject();
                });

            function getResourceFieldValues(field) {
                return _.get(_.find($rootScope.global.resourceFields.user, {'name': field}), 'values');
            }

            function setActiveValues() {
                vm.activeValues = getResourceFieldValues('active');
                return $q.when();
            }
        }

        /**
         * Set default value for new resource.
         */
        function setNew() {
            if ($scope.shared.editor.initialized) {
                $scope.shared.editor.entity = {
                    id: undefined,
                    name: '',
                    email: '',
                    active: vm.activeValues[1].value
                };
            }
        }

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            $scope.shared.editor.initialize = initialize;
            $scope.shared.editor.setNew = setNew;
        }

        activate();
    }
})();
