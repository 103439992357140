(function () {
    angular
        .module('app')
        .controller('LookupController', LookupController);

    // Define controller dependencies.
    LookupController.$inject = ['$mdDialog', '$mdToast', '$rootScope', '$scope', '$timeout', 'DataListFactory', 'FocusService', 'hotkeys', 'lookupDataListFactory', 'isSelectable'];

    /**
     * Controller for lookup resource dialog.
     *
     * @param $mdDialog
     * @param $mdToast
     * @param $rootScope
     * @param $scope
     * @param $timeout
     * @param DataListFactory
     * @param FocusService
     * @param hotkeys
     * @param lookupDataListFactory
     * @param isSelectable
     * @constructor
     */
    function LookupController($mdDialog, $mdToast, $rootScope, $scope, $timeout, DataListFactory, FocusService, hotkeys, lookupDataListFactory, isSelectable) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.dataListTemplate = 'shared/data-list/data-list.view.html';
        vm.factory = undefined;

        /**
         * Bindable methods.
         */
        vm.select = select;
        vm.clear = clear;
        vm.close = close;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Close the lookup dialog by passing any selected items.
         */
        function select() {
            var selected = vm.factory.gridApi.selection.getSelectedRows();
            if (selected.length === 0) {
                // No items selected
                $mdDialog.cancel();
            } else {
                // Items selected.
                if (!angular.isDefined(isSelectable)) {
                    $mdDialog.hide(selected);
                } else if (isSelectable(selected)) {
                    $mdDialog.hide(selected);
                } else {
                    $mdToast.show({
                        templateUrl: 'shared/lookup/lookup-message.view.html',
                        parent : angular.element(document.body),
                        hideDelay: 3000,
                        position: 'bottom left'
                    });
                }
            }
        }

        /**
         * Close the lookup dialog by passing null as selected item.
         */
        function clear() {
            var selected = null;
            $mdDialog.hide(selected);
        }

        /**
         * Close the dialog.
         */
        function close() {
            $mdDialog.cancel();
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Set bindable members.
            vm.factory = lookupDataListFactory;

            // Set active data list factory to newly created 'ad-hoc' DataListFactory.
            $rootScope.global.activeDataListFactory = lookupDataListFactory;

            // On $destroy of this controller, set active data list factory back to the main one.
            $scope.$on('$destroy', function () {
                $rootScope.global.activeDataListFactory = DataListFactory;
            });

            // Binding keyboard shortcuts.
            hotkeys.bindTo($scope)
                .add({
                    combo: 'space',
                    description: 'Select rows',
                    callback: function () {
                        vm.select();
                    }
                });

            // Set focus to quick search input.
            $timeout(function () {
                FocusService.focus('md-dialog .quick-search');
            }, 0);
            // Set focus twice to deal with ui-grid cell editor feature.
            $timeout(function () {
                FocusService.focus('md-dialog .quick-search');
            }, 500);
        }

        activate();
    }
})();
