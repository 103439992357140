(function () {
    'use strict';

    angular
        .module('app', [
            // Template cache
            'templates',
            // Module dependencies
            'cfp.hotkeys',
            'mdPickers',
            'ngAnimate',
            'ngMaterial',
            'restangular',
            'sasrio.angular-material-sidenav',
            'ui.grid',
            'ui.grid.cellNav',
            'ui.grid.pagination',
            'ui.grid.pinning',
            'ui.grid.resizeColumns',
            'ui.grid.selection',
            'ui.router'
        ]);
})();
