(function () {
    'use strict';

    angular
        .module('app')
        .controller('CRUDController', CRUDController);

    // Define controller dependencies.
    CRUDController.$inject = ['$mdDialog', '$rootScope', '$scope', '$timeout', '$window', 'CRUDFactory', 'DataListFactory', 'FocusService', 'hotkeys', 'uiGridConstants'];

    /**
     * Controller for CRUD abstract state.
     *
     * @param $mdDialog
     * @param $rootScope
     * @param $scope
     * @param $timeout
     * @param $window
     * @param CRUDFactory
     * @param DataListFactory
     * @param FocusService
     * @param hotkeys
     * @constructor
     */
    function CRUDController($mdDialog, $rootScope, $scope, $timeout, $window, CRUDFactory, DataListFactory, FocusService, hotkeys, uiGridConstants) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * Constants.
         */
        const DATA_LIST_OPEN = 0,
            EDITOR_OPEN = 1,
            READER_OPEN = 2;

        /**
         * This controller.
         */
        var vm = this;

        /**
         * Resource's CRUD controller default settings
         */
        var sharedDefault = {
            /**
             * Disable updating a resource.
             */
            updateDisabled: false,
            /**
             * Disable destroying a resource.
             */
            destroyDisabled: false,
            /**
             * Disable printing a resource.
             */
            printDisabled: false,
            /**
             * CRUD is printing a resource.
             */
            printing: false,
            /**
             * Data List related settings.
             */
            dataList: {},
            /**
             * Editor related settings.
             */
            editor: {
                /**
                 * Binding model representing a resource being updated or inserted.
                 */
                entity: {},
                /**
                 * Editor's template for certain resource.
                 */
                template: '',
                /**
                 * Editor successfully loaded the updated resource.
                 */
                entityExist: false,
                /**
                 * Editor is loading the updated resource.
                 */
                loading: true,
                /**
                 * Editor is saving either the inserted or updated resource.
                 */
                saving: false,
                /**
                 * Editor's data source is initialized.
                 */
                initialized: false,
                /**
                 * Editor is in update mode.
                 */
                updateMode: false,
                /**
                 * First input control to focus on.
                 */
                focusElement: '',
                /**
                 * Clear entity binding.
                 */
                clear: function () {
                    $scope.shared.editor.entity = {};
                    $scope.shared.editor.entityExist = false;
                },
                /**
                 * Initialize editor's data source for inserting new resource.
                 */
                initialize: function () {},
                /**
                 * Set entity to its default value for inserting new resource.
                 */
                setNew: function () {},
                /**
                 * Set new entity and then focus on first element.
                 */
                reset: function () {
                    $scope.shared.editor.initialize()
                        .then($scope.shared.editor.setNew);
                    $timeout(function () {
                        FocusService.focus($scope.shared.editor.focusElement);
                    }, 1000);
                }
            },
            /**
             * Reader related settings.
             */
            reader: {
                /**
                 * Binding model representing a resource being read.
                 */
                entity: {},
                /**
                 * Reader's template for certain resource.
                 */
                template: '',
                /**
                 * Reader successfully loaded the read resource.
                 */
                entityExist: false,
                /**
                 * Reader is loading the read resource.
                 */
                loading: true,
                /**
                 * Clear entity binding.
                 */
                clear: function () {
                    $scope.shared.reader.entity = {};
                    $scope.shared.reader.entityExist = false;
                }
            }
        };

        /**
         * Current state informations.
         */
        var baseState = '';
        var subState = '';
        var stateParams = undefined;
        var autoRefreshDataList = false;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.activeTab;

        /**
         * Bindable methods.
         */
        vm.showDataList = showDataList;
        vm.showEditor = showEditor;
        vm.showReader = showReader;
        vm.create = create;
        vm.read = read;
        vm.update = update;
        vm.destroy = destroy;
        vm.print = print;
        vm.save = save;
        vm.getCurrentState = getCurrentState;
        vm.previousResource = previousResource;
        vm.nextResource = nextResource;
        vm.setFABDirection = setFABDirection;

        /**
         * CRUD Controller level shared object that extends
         * its parent controller (MainController) and will
         * be accessible for all of its child controllers.
         */
        $scope.shared = createShared();

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * Create new shared object.
         *
         * @returns {*}
         */
        function createShared() {
            return angular.extend(
                // New shared object.
                {},
                // Shared property from parent controller (MainController).
                $scope.shared,
                // This controller shared property.
                angular.copy(sharedDefault)
            );
        };

        /**
         * Change CRUD layout based on sub state, that is
         * create, update, read, or default (data list).
         */
        function changeLayout() {
            switch (subState) {
                case 'data-list':
                    vm.activeTab = DATA_LIST_OPEN;

                    // Auto refresh data list if user has created or updated a data.
                    if (autoRefreshDataList) {
                        DataListFactory.search();
                        autoRefreshDataList = false;
                    }

                    // Clear editor and reader entity so
                    // that showEditor() and showReader()
                    // will use new populated data list.
                    $scope.shared.editor.clear();
                    $scope.shared.reader.clear();
                    break;
                case 'create':
                    vm.activeTab = EDITOR_OPEN;
                    $scope.shared.editor.reset();
                    $scope.shared.editor.loading = false;
                    break;
                case 'read':
                    vm.activeTab = READER_OPEN;
                    doRead(stateParams.id);
                    break;
                case 'update':
                    vm.activeTab = EDITOR_OPEN;
                    $scope.shared.editor.reset();
                    doEdit(stateParams.id);
                    break;
                default:
                    break;
            }
        }

        /**
         * Set update mode based on current substate.
         */
        function setUpdateMode() {
            switch (subState) {
                case 'data-list':
                    $scope.shared.editor.updateMode = false;
                    break;
                case 'create':
                    $scope.shared.editor.updateMode = false;
                    break;
                case 'read':
                    $scope.shared.editor.updateMode = false;
                    break;
                case 'update':
                    $scope.shared.editor.updateMode = true;
                    break;
                default:
                    break;
            }
        }

        /**
         * Reset current active tabs.
         */
        function resetActiveTab() {
            switch (subState) {
                case 'data-list':
                    vm.activeTab = DATA_LIST_OPEN;
                    break;
                case 'create':
                    vm.activeTab = EDITOR_OPEN;
                    break;
                case 'read':
                    vm.activeTab = EDITOR_OPEN;
                    break;
                case 'update':
                    vm.activeTab = READER_OPEN;
                    break;
                default:
                    break;
            }
        }

        /**
         * Create a new resource.
         */
        function doCreate() {
            $scope.shared.editor.saving = true;
            CRUDFactory.create($scope.shared.editor.entity)
                .then(function (id) {
                    immediatePrintLastResource(id);
                    $scope.shared.editor.clear();
                    $scope.shared.editor.reset();
                })
                .finally(function () {
                    $scope.shared.editor.saving = false;
                });
        }

        /**
         * Read a resource.
         *
         * @param id
         */
        function doRead(id) {
            $scope.shared.reader.loading = true;
            CRUDFactory.read(id)
                .then(function (entity) {
                    $scope.shared.reader.entity = entity;
                    $scope.shared.reader.entityExist = true;
                }, function () {
                    $scope.shared.reader.clear();
                })
                .finally(function () {
                    $scope.shared.reader.loading = false;
                });
        }

        /**
         * Edit a resource for update.
         *
         * @param id
         */
        function doEdit(id) {
            $scope.shared.editor.loading = true;
            CRUDFactory.edit(id)
                .then(function (entity) {
                    $scope.shared.editor.entity = entity;
                    $scope.shared.editor.entityExist = true;
                    // Wait for animation to finish.
                    $timeout(function () {
                        FocusService.focus($scope.shared.editor.focusElement);
                    }, 1000);
                }, function () {
                    $scope.shared.editor.clear();
                })
                .finally(function () {
                    $scope.shared.editor.loading = false;
                });
        }

        /**
         * Update a resource.
         */
        function doUpdate() {
            $scope.shared.editor.saving = true;
            CRUDFactory.update($scope.shared.editor.entity)
                .then(function () {
                    immediatePrintLastResource($scope.shared.editor.entity.id);
                    $scope.shared.editor.reset();
                    doEdit(stateParams.id);
                })
                .finally(function () {
                    $scope.shared.editor.saving = false;
                });
        }

        /**
         * Destroy a resource.
         *
         * @param ids
         */
        function doDestroy(ids) {
            vm.showDataList();
            DataListFactory.setBusy();
            CRUDFactory.destroy(ids)
                .then(function () {
                    DataListFactory.setNotBusy();
                    DataListFactory.search();
                }, function () {
                    DataListFactory.setNotBusy();
                });
        }

        /**
         * Print a resource.
         *
         * @param id
         */
        function doPrint(id) {
            $scope.shared.printing = true;
            CRUDFactory.print(id)
                .finally(function () {
                    $scope.shared.printing = false;
                });
        }

        /**
         * Immediately print the last resource.
         *
         * @param id
         */
        function immediatePrintLastResource(id) {
            // Skip immediate print of last  resource if print resource is disabled.
            if ($scope.shared.printDisabled) return;

            $mdDialog.show(
                $mdDialog.confirm()
                    .title('Would you like to print?')
                    .textContent('A PDF print out of last resource will be downloaded.')
                    .ariaLabel('Print Resource')
                    .ok('Print Now')
                    .cancel('Skip It')
            )
                .then(function () {
                    doPrint(id);
                })
                .finally(function () {
                    FocusService.focus($scope.shared.editor.focusElement);
                });
        }

        /**
         * Find data list's current row entity index.
         */
        function getDataListCurrentRowIndex() {
            var currentRow = DataListFactory.gridApi.selection.getSelectedRows()[0];
            var currentRowIndex = null;

            for(var i=0; i<DataListFactory.gridApi.grid.rows.length; i++) {
                if (DataListFactory.gridApi.grid.rows[i].entity.$$hashKey == currentRow.$$hashKey) {
                    currentRowIndex = i;
                    break;
                };
            }

            return currentRowIndex;
        }

        /**
         * Change current resource being update or read.
         *
         * @param step
         */
        function changeCurrentResource(step) {
            var currentRowIndex = getDataListCurrentRowIndex();
            var targetRowIndex = currentRowIndex === null ? 0 : currentRowIndex + step;

            if (currentRowIndex === null) {
                // Data list is empty
                beginDataListLoading();
                // Fetch data list's first page
                DataListFactory.search()
                    .then(function () {
                        targetRowIndex = 0;
                        doChangeCurrentResource();
                    }).catch(endDataListLoading);
            } else if (targetRowIndex < 0) {
                // Target row is on previous page.
                beginDataListLoading();
                // Fetch data list's previous page
                DataListFactory.previous()
                    .then(function () {
                        targetRowIndex = DataListFactory.gridOptions.take - 1;
                        doChangeCurrentResource();
                    }).catch(endDataListLoading);
            } else if (targetRowIndex >= DataListFactory.gridOptions.take) {
                // Target row is on next page.
                beginDataListLoading();
                // Fetch data list's next page
                DataListFactory.next()
                    .then(function () {
                        targetRowIndex = 0
                        doChangeCurrentResource();
                    }).catch(endDataListLoading);
            } else {
                // Target row is on current page.
                if (targetRowIndex < DataListFactory.gridApi.grid.rows.length) {
                    // Only if targetRowIndex's row is actually exist
                    doChangeCurrentResource();
                }
            }

            /**
             * Start loading animation.
             */
            function beginDataListLoading() {
                if (subState == 'update') {
                    $scope.shared.editor.loading = true;
                } else if (subState == 'read') {
                    $scope.shared.reader.loading = true;
                }
            }

            /**
             * Stop loading animation.
             */
            function endDataListLoading() {
                if (subState == 'update') {
                    $scope.shared.editor.loading = false;
                } else if (subState == 'read') {
                    $scope.shared.reader.loading = false;
                }
            }

            /**
             * Actually change the current resource.
             */
            function doChangeCurrentResource() {
                // Notify the grid to render manually here because the grid is currently not visible
                DataListFactory.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ROW);

                // Use timeout to wait for the grid to propagate the data change
                $timeout(function () {
                    var currentDataListId = DataListFactory.gridApi.grid.rows[targetRowIndex].entity.id;
                    if ($scope.shared.editor.entity.id == currentDataListId ||
                        $scope.shared.reader.entity.id == currentDataListId) {
                        // There's a chance that the target resource from the data list
                        // is the one that being update or read. So we simply change
                        // again current resource if we already in that resource.
                        changeCurrentResource(step);
                    } else {
                        // Move data list selection to the target resource
                        DataListFactory.gridApi.selection.clearSelectedRows();
                        DataListFactory.gridApi.selection.selectRow(DataListFactory.gridApi.grid.rows[targetRowIndex].entity);
                        DataListFactory.gridApi.cellNav.scrollToFocus(
                            DataListFactory.gridApi.grid.rows[targetRowIndex].entity,
                            DataListFactory.gridApi.grid.options.columnDefs[0]
                        )

                        // Do update and read for target resource
                        if (subState == 'update') {
                            vm.update();
                        } else if (subState == 'read') {
                            vm.read();
                        }
                    }
                }, 0);
            }
        }

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Show data list.
         */
        function showDataList() {
            $scope.shared.$state.go(baseState + '.data-list');
            vm.activeTab = DATA_LIST_OPEN;
            $scope.shared.scrollTo('top');
        }

        /**
         * Show editor.
         */
        function showEditor() {
            // Check if in create mode.
            if (subState == 'create') { resetActiveTab(); return; }

            if (DataListFactory.gridApi.grid.rows.length == 0) {
                // Data list is empty
                if (angular.isObject($scope.shared.reader.entity) && angular.isDefined($scope.shared.reader.entity.id)) {
                    // Show editor update mode with resource based on reader's entity.
                    $scope.shared.$state.go(baseState + '.update', {id: $scope.shared.reader.entity.id});
                } else {
                    // Show editor create mode.
                    $scope.shared.$state.go(baseState + '.create');
                }
            } else {
                // Data list is not empty
                if (DataListFactory.gridApi.selection.getSelectedGridRows().length > 0) {
                    // Show editor update mode with resource based on reader's entity.
                    $scope.shared.$state.go(baseState + '.update', {id: DataListFactory.selectSingleRow().entity.id});
                } else {
                    // Do nothing and reset active tab based on current substate.
                    resetActiveTab();
                    return;
                }
            }

            $scope.shared.scrollTo('top');
        }

        /**
         * Show reader.
         */
        function showReader() {
            // Check if in create mode.
            if (subState == 'create') { resetActiveTab(); return; }

            if (DataListFactory.gridApi.grid.rows.length == 0) {
                // Data list is empty
                if (angular.isObject($scope.shared.editor.entity) && angular.isDefined($scope.shared.editor.entity.id)) {
                    // Show reader with resource based on editor's entity.
                    $scope.shared.$state.go(baseState + '.read', {id: $scope.shared.editor.entity.id});
                } else {
                    // Show data list.
                    vm.showDataList();
                }
            } else {
                // Data list is not empty
                if (DataListFactory.gridApi.selection.getSelectedGridRows().length > 0) {
                    // Show reader with resource based on data list selection.
                    $scope.shared.$state.go(baseState + '.read', {id: DataListFactory.selectSingleRow().entity.id});
                } else {
                    // Do nothing and reset active tab based on current substate.
                    resetActiveTab();
                    return;
                }
            }

            $scope.shared.scrollTo('top');
        }

        /**
         * Prepare to create a resource.
         */
        function create() {
            $scope.shared.$state.go(baseState + '.create');
        }

        /**
         * Prepare to read a resource.
         */
        function read() {
            showReader();
        }

        /**
         * Prepare to update a resource.
         */
        function update() {
            // Check if update is disabled.
            if ($scope.shared.updateDisabled) return;

            showEditor();
        }

        /**
         * Prepare to destroy a resource.
         */
        function destroy() {
            // Check if in create mode.
            if (subState == 'create') { resetActiveTab(); return; }

            // Check if destroy is disabled.
            if ($scope.shared.destroyDisabled) return;

            var ids = [];

            if (DataListFactory.gridApi.grid.rows.length == 0) {
                // Data list is empty
                // Destroy resource based on editor's or reader's entity.
                if (angular.isObject($scope.shared.editor.entity) && angular.isDefined($scope.shared.editor.entity.id)) {
                    ids.push($scope.shared.editor.entity.id);
                } else if (angular.isObject($scope.shared.reader.entity) && angular.isDefined($scope.shared.reader.entity.id)) {
                    ids.push($scope.shared.reader.entity.id);
                } else {
                    return;
                }
            } else {
                // Data list is not empty
                if (DataListFactory.gridApi.selection.getSelectedGridRows().length > 0) {
                    // Destroy resource based on data list selection.
                    DataListFactory.gridApi.selection.getSelectedGridRows().forEach(function (value, key) {
                        ids.push(value.entity.id);
                    });
                } else {
                    // Do nothing.
                    return;
                }
            }

            // Show destroy confirmation dialog.
            $mdDialog.show(
                $mdDialog.confirm()
                    .title('Are you sure want to destroy selected items?')
                    .textContent(ids.length + ' items will be destroyed. All destroyed items will be permanently destroyed and can not be restored.')
                    .ok('Destroy')
                    .cancel('Cancel')
            ).then(function () {
                    // Destroy selected items.
                    doDestroy(ids);
                });
        }

        /**
         * Prepare to print a resource.
         */
        function print() {
            // Check if in create mode.
            if (subState == 'create') { resetActiveTab(); return; }

            // Check if print is disabled.
            if ($scope.shared.printDisabled) return;

            var id;

            if (DataListFactory.gridApi.grid.rows.length == 0) {
                // Data list is empty
                // Print resource based on editor's or reader's entity.
                if (angular.isObject($scope.shared.editor.entity) && angular.isDefined($scope.shared.editor.entity.id)) {
                    id = $scope.shared.editor.entity.id;
                } else if (angular.isObject($scope.shared.reader.entity) && angular.isDefined($scope.shared.reader.entity.id)) {
                    id = $scope.shared.reader.entity.id;
                } else {
                    return;
                }
            } else {
                // Data list is not empty
                if (DataListFactory.gridApi.selection.getSelectedGridRows().length > 0) {
                    // Print resource based on data list selection.
                    id = DataListFactory.selectSingleRow().entity.id;
                } else {
                    // Do nothing.
                    return;
                }
            }

            doPrint(id);
        }

        /**
         * Create new or update existing resource.
         */
        function save() {
            if ($scope.shared.editor.updateMode) {
                doUpdate();
            } else {
                doCreate();
            }

            // Set data list to auto refresh on next data list open
            autoRefreshDataList = true;
        }

        /**
         * Get current active state info.
         */
        function getCurrentState() {
            return {
                baseState: baseState,
                subState: subState,
                stateParams: stateParams
            }
        }

        /**
         * Go to previous resource.
         */
        function previousResource() {
            changeCurrentResource(-1);
        }

        /**
         * Go to next resource.
         */
        function nextResource() {
            changeCurrentResource(1);
        }

        /**
         * Decide FAB direction based on screen height.
         */
        function setFABDirection() {
            return $window.innerHeight > 380 ? 'up' : 'left';
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Reset shared object if base state is going to be changed.
            $scope.$on('$stateChangeStart',
                function(event, toState, toParams, fromState, fromParams) {
                    if (toState.name.split('.').slice(0,2).join('.') != baseState) {
                        $scope.shared = createShared();
                    }
                }
            );

            // Change layout based on new sub state.
            $scope.$on('$stateChangeSuccess',
                function(event, toState, toParams, fromState, fromParams) {
                    var toStateName = toState.name.split('.');

                    // Store new current sub state.
                    subState = (toStateName.length == 3) ? toStateName.pop() : '';

                    // Set auto refresh data list to false only if base state is changed
                    if (baseState != toStateName.join('.')) {
                        autoRefreshDataList = false;
                    }

                    // Store new current state params.
                    stateParams = toParams;

                    // Store new current base state.
                    baseState = toStateName.join('.');

                    // Set update mode
                    setUpdateMode();

                    // Defer change layout so that data list waiting animation works.
                    $timeout(function () {
                        if ($rootScope.global.booting) {
                            $scope.$on('app.booted', function () {
                                changeLayout();
                            });
                        } else {
                            changeLayout();
                        }
                    }, 0);
                }
            );

            // CRUD responses on active storage changed.
            $scope.$on('MainController.activeStorageChanged', function () {
                switch (subState) {
                    case 'data-list':
                        // Re-search data list.
                        DataListFactory.search();
                        break;
                    case 'create':
                        // Re-initialize editor and then setNew.
                        $scope.shared.editor.reset();
                        break;
                    case 'update':
                        // Re-edit resource.
                        doEdit(stateParams.id);
                        break;
                    case 'read':
                        // Re-read resource.
                        doRead(stateParams.id);
                        break;
                }
            });

            // Binding keyboard shortcuts.
            hotkeys.bindTo($scope)
                .add({
                    combo: 'ctrl+alt+c',
                    description: 'Create new resource',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { vm.create(); }
                })
                .add({
                    combo: 'ctrl+alt+r',
                    description: 'Read selected resource',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { vm.read(); }
                })
                .add({
                    combo: 'ctrl+alt+u',
                    description: 'Update selected resource',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { vm.update(); }
                })
                .add({
                    combo: 'ctrl+alt+d',
                    description: 'Delete selected resource',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { vm.destroy(); }
                })
                .add({
                    combo: 'ctrl+alt+p',
                    description: 'Print selected resource',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { vm.print(); }
                })
                .add({
                    combo: 'ctrl+alt+s',
                    description: 'Insert/Update resource',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () {
                        if ($scope.shared.editor.entityExist) {
                            doUpdate();
                        } else {
                            doCreate();
                        }
                    }
                })
                .add({
                    combo: 'ctrl+alt+backspace',
                    description: 'Back to data list',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { vm.showDataList(); }
                });
        }

        activate();
    }
})();
