(function () {
    'use strict';

    angular
        .module('app')
        .config(config)
        .run(run);

    // Define config dependencies.
    config.$inject = ['$httpProvider', '$mdThemingProvider', '$provide', 'ssSideNavSectionsProvider'];

    /**
     * Angular config block.
     *
     * @param $httpProvider
     * @param $mdThemingProvider
     * @param hotkeysProvider
     * @param ssSideNavSectionsProvider
     */
    function config($httpProvider, $mdThemingProvider, $provide, ssSideNavSectionsProvider) {
        // Show alert to user if unhandled exception is thrown.
        $provide.decorator('$exceptionHandler', ['$delegate', function ($delegate) {
            return function (exception, cause) {
                $delegate(exception, cause);
                alert(
                    'Ooops..! Something went wrong.\n\n' +
                    'Don\'t panic, just contact your friendly EDP and tell him this message.\n\n' +
                    '"' + exception.name + ': ' + exception.message + '"' +
                    '\n\nSincerely,\n' +
                    'Your friendly EDP :)'
                );
            };
        }]);

        // Angular material theme.
        $mdThemingProvider.theme('default')
            .primaryPalette('blue')
            .accentPalette('pink')
            .warnPalette('red')
            .backgroundPalette('grey');

        // Angular material sidenav's menu definitions.
        ssSideNavSectionsProvider.initWithSections([{
            id: 'home',
            name: 'Home',
            type: 'link',
            state: 'home'
        }, {
            id: 'master',
            name: 'Master',
            type: 'toggle',
            pages: [{
                id: 'master_user',
                name: 'User',
                state: 'master.user.data-list'
            }, {
                id: 'master_application',
                name: 'Application',
                state: 'master.application.data-list'
            }, {
                id: 'master_version',
                name: 'Version',
                state: 'master.version.data-list'
            }, {
                id: 'master_role',
                name: 'Role',
                state: 'master.role.data-list'
            }, {
                id: 'master_permission',
                name: 'Permission',
                state: 'master.permission.data-list'
            }, {
                id: 'master_workgroup',
                name: 'Workgroup',
                state: 'master.workgroup.data-list'
            }, {
                id: 'master_storage',
                name: 'Storage',
                state: 'master.storage.data-list'
            }]
        }, {
            id: 'register',
            name: 'Register',
            type: 'toggle',
            pages: [{
                id: 'register_user',
                name: 'User',
                state: 'register.user'
            }, {
                id: 'register_role',
                name: 'Role',
                state: 'register.role'
            }, {
                id: 'register_workgroup',
                name: 'Workgroup',
                state: 'register.workgroup'
            }]
        }, {
            id: 'report',
            name: 'Report',
            type: 'toggle',
            pages: [{
                id: 'report_user',
                name: 'User',
                state: 'report.user'
            }, {
                id: 'report_application',
                name: 'Application',
                state: 'report.application'
            }, {
                id: 'report_version',
                name: 'Version',
                state: 'report.version'
            }, {
                id: 'report_role',
                name: 'Role',
                state: 'report.role'
            }, {
                id: 'report_permission',
                name: 'Permission',
                state: 'report.permission'
            }, {
                id: 'report_workgroup',
                name: 'Workgroup',
                state: 'report.workgroup'
            }, {
                id: 'report_storage',
                name: 'Storage',
                state: 'report.storage'
            }]
        }
        ]);
        ssSideNavSectionsProvider.initWithTheme($mdThemingProvider);

        // Angular interceptors.
        var interceptors = ['$injector', '$q',
            function interceptors($injector, $q) {
                return {
                    request: function (config) {
                        // Intercept request to add JWT token as Authorization header.
                        $injector.get('JWTFactory').applyToken(config);

                        return config;
                    },
                    response: function (response) {
                        // Intercept response to show info message, if 'message' field is provided.
                        $injector.get('InfoMessageHandlerFactory').handle(response);

                        return response;
                    },
                    responseError: function (rejection) {
                        // Intercept error response to show error message.
                        $injector.get('ErrorMessageHandlerFactory').handle(rejection);

                        return $q.reject(rejection);
                    }
                };
            }
        ];
        // Register the interceptors.
        $httpProvider.interceptors.push(interceptors);

        // Set X-Requested-With header so our request will be processed as AJAX by Laravel.
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    }

    // Define run dependencies.
    run.$inject = ['$location', '$rootScope', 'JWTFactory', 'PermissionFactory', 'Restangular'];

    /**
     * Angular run block.
     *
     * @param $location
     * @param $rootScope
     * @param JWTFactory
     * @param PermissionFactory
     * @param Restangular
     */
    function run($location, $rootScope, JWTFactory, PermissionFactory, Restangular) {
        // Parse angular route.
        if ($location.search().route) {
            $location.path($location.search().route);
            $location.search('route', null);
        }

        // Get reference of lodash from window
        // into $rootScope so we can use it
        // directly from angular expression.
        $rootScope._ = _;

        /**
         * Get date time auto complete values.
         *
         * @param dateFormat
         * @returns {*[]}
         */
        function getDateTimeValues(dateFormat) {
            return [
                { value: 'Start Of Today', display: moment().startOf('day').format(dateFormat) + ' (Start Of Today)' },
                { value: 'End Of Today', display: moment().endOf('day').format(dateFormat) + ' (End Of Today)' },
                { value: 'This Day', display: moment().startOf('day').format(dateFormat) + ' | ' + moment().endOf('day').format(dateFormat) + ' (This Day)' },
                { value: 'This Week', display: moment().startOf('week').format(dateFormat) + ' | ' + moment().endOf('week').format(dateFormat) + ' (This Week)' },
                { value: 'This Month', display: moment().startOf('month').format(dateFormat) + ' | ' + moment().endOf('month').format(dateFormat) + ' (This Month)' },
                { value: 'This Year', display: moment().startOf('year').format(dateFormat) + ' | ' + moment().endOf('year').format(dateFormat) + ' (This Year)' },
                { value: 'Start Of Yesterday', display: moment().startOf('day').subtract(1, 'days').format(dateFormat) + ' (Start Of Yesterday)' },
                { value: 'End Of Yesterday', display: moment().endOf('day').subtract(1, 'days').format(dateFormat) + ' (End Of Yesterday)' },
                { value: 'Last Day', display: moment().startOf('day').subtract(1, 'days').format(dateFormat) + ' | ' + moment().endOf('day').subtract(1, 'days').format(dateFormat) + ' (Last Day)' },
                { value: 'Last Week', display: moment().startOf('week').subtract(1, 'weeks').format(dateFormat) + ' | ' + moment().endOf('week').subtract(1, 'weeks').format(dateFormat) + ' (Last Week)' },
                { value: 'Last Month', display: moment().startOf('month').subtract(1, 'month').format(dateFormat) + ' | ' + moment().endOf('month').subtract(1, 'month').format(dateFormat) + ' (Last Month)' },
                { value: 'Last Year', display: moment().startOf('year').subtract(1, 'year').format(dateFormat) + ' | ' + moment().endOf('year').subtract(1, 'year').format(dateFormat) + ' (Last Year)' },
                { value: 'Start Of Tomorrow', display: moment().startOf('day').add(1, 'days').format(dateFormat) + ' (Start Of Tomorrow)' },
                { value: 'End Of Tomorrow', display: moment().endOf('day').add(1, 'days').format(dateFormat) + ' (End Of Tomorrow)' },
                { value: 'Next Day', display: moment().startOf('day').add(1, 'days').format(dateFormat) + ' | ' + moment().endOf('day').add(1, 'days').format(dateFormat) + ' (Next Day)' },
                { value: 'Next Week', display: moment().startOf('week').add(1, 'weeks').format(dateFormat) + ' | ' + moment().endOf('week').add(1, 'weeks').format(dateFormat) + ' (Next Week)' },
                { value: 'Next Month', display: moment().startOf('month').add(1, 'month').format(dateFormat) + ' | ' + moment().endOf('month').add(1, 'month').format(dateFormat) + ' (Next Month)' },
                { value: 'Next Year', display: moment().startOf('year').add(1, 'year').format(dateFormat) + ' | ' + moment().endOf('year').add(1, 'year').format(dateFormat) + ' (Next Year)' }
            ]
        }

        // Set global object.
        $rootScope.global = {
            version: helpers.getMetaContent('app:version'),
            baseUrl: 'https://uam-staging.jangkarpacific.com',
            uamUrl: 'https://uam-staging.jangkarpacific.com',
            appsUrl: 'https://apps-staging.jangkarpacific.com',
            loginUrl: 'https://apps-staging.jangkarpacific.com/?app=1',
            user: undefined,
            APIPartners: undefined,
            numberOfRequest: 0,
            refreshingToken: false,
            booting: true,
            activeDataListFactory: null,
            isAdministrator: function () {
                if ($rootScope.global.user !== undefined) {
                    return _.find($rootScope.global.user.roles, { administrator: true }) === undefined ? false : true;
                } else {
                    return false;
                }
            },
            isGuest: function () {
                if ($rootScope.global.user !== undefined) {
                    return _.find($rootScope.global.user.roles, { guest: true }) === undefined ? false : true;
                } else {
                    return false;
                }
            },
            resourceFields: {
                common: [
                    { name: 'id', searchable: true, values: [] },
                    { name: 'created_by', searchable: true, values: [] },
                    { name: 'updated_by', searchable: true, values: [] },
                    {
                        name: 'created_at', searchable: true, values: (function () {
                            return getDateTimeValues('DD-MM-YYYY HH:mm:ss');
                        })()
                    },
                    {
                        name: 'updated_at', searchable: true, values: (function () {
                            return getDateTimeValues('DD-MM-YYYY HH:mm:ss');
                        })()
                    }
                ],
                user: [
                    { name: 'name', searchable: true, values: [] },
                    { name: 'email', searchable: true, values: [] },
                    {
                        name: 'password_expiry', searchable: true, values: (function () {
                            return getDateTimeValues('DD-MM-YYYY');
                        })()
                    },
                    {
                        name: 'active', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    }
                ],
                application: [
                    { name: 'code', searchable: true, values: [] },
                    { name: 'name', searchable: true, values: [] },
                    { name: 'description', searchable: true, values: [] },
                    { name: 'app_url', searchable: true, values: [] },
                    { name: 'partners', searchable: false, values: [] },
                    {
                        name: 'active', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    }
                ],
                version: [
                    { name: 'version', searchable: true, values: [] },
                    { name: 'log', searchable: true, values: [] }
                ],
                role: [
                    { name: 'name', searchable: true, values: [] },
                    { name: 'description', searchable: true, values: [] },
                    {
                        name: 'administrator', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    },
                    {
                        name: 'guest', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    },
                    {
                        name: 'active', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    }
                ],
                permission: [
                    { name: 'name', searchable: true, values: [] },
                    {
                        name: 'active', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    }
                ],
                workgroup: [
                    { name: 'name', searchable: true, values: [] },
                    { name: 'description', searchable: true, values: [] },
                    { name: 'letterhead_initial', searchable: true, values: [] },
                    { name: 'letterhead_company', searchable: true, values: [] },
                    { name: 'letterhead_tagline', searchable: true, values: [] },
                    { name: 'letterhead_address', searchable: true, values: [] },
                    {
                        name: 'active', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    }
                ],
                storage: [
                    { name: 'name', searchable: true, values: [] },
                    { name: 'driver', searchable: true, values: [] },
                    { name: 'host', searchable: true, values: [] },
                    { name: 'database', searchable: true, values: [] },
                    { name: 'username', searchable: true, values: [] },
                    {
                        name: 'read_only', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    },
                    {
                        name: 'update_only', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    },
                    {
                        name: 'backup', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    },
                    {
                        name: 'active', searchable: true, values: [
                            { value: false, display: 'FALSE' },
                            { value: true, display: 'TRUE' }
                        ]
                    }
                ]
            },
            searchResourceFields: {
                user: [
                    { resource: 'user', relation: 'this' },
                    { resource: 'application', relation: 'applications' },
                    { resource: 'role', relation: 'roles' },
                    { resource: 'permission', relation: 'permissions' },
                    { resource: 'workgroup', relation: 'workgroups' }
                ],
                application: [
                    { resource: 'application', relation: 'this' },
                    { resource: 'version', relation: 'versions' },
                    { resource: 'role', relation: 'roles' },
                    { resource: 'permission', relation: 'permissions' },
                    { resource: 'workgroup', relation: 'workgroups' },
                    { resource: 'storage', relation: 'storages' },
                    { resource: 'user', relation: 'users' }
                ],
                version: [
                    { resource: 'version', relation: 'this' },
                    { resource: 'application', relation: 'application' }
                ],
                role: [
                    { resource: 'role', relation: 'this' },
                    { resource: 'application', relation: 'application' },
                    { resource: 'permission', relation: 'permissions' },
                    { resource: 'user', relation: 'users' }
                ],
                permission: [
                    { resource: 'permission', relation: 'this' },
                    { resource: 'application', relation: 'application' },
                    { resource: 'role', relation: 'roles' },
                    { resource: 'user', relation: 'users' }
                ],
                workgroup: [
                    { resource: 'workgroup', relation: 'this' },
                    { resource: 'application', relation: 'application' },
                    { resource: 'storage', relation: 'storages' },
                    { resource: 'user', relation: 'users' }
                ],
                storage: [
                    { resource: 'storage', relation: 'this' },
                    { resource: 'application', relation: 'application' },
                    { resource: 'workgroup', relation: 'workgroups' }
                ]
            },
            columnDefs: {
                common: [
                    { field: 'id', minWidth: 60, width: 60 },
                    { field: 'workgroup', minWidth: 160, width: 160 },
                    { field: 'created_by', minWidth: 160, width: 160 },
                    { field: 'updated_by', minWidth: 160, width: 160 },
                    {
                        field: 'created_at',
                        minWidth: 200,
                        width: 200,
                        cellFilter: "date:'dd-MM-yyyy HH:mm:ss'",
                        type: 'date'
                    },
                    {
                        field: 'updated_at',
                        minWidth: 200,
                        width: 200,
                        cellFilter: "date:'dd-MM-yyyy HH:mm:ss'",
                        type: 'date'
                    }
                ],
                commonRelation: [
                    { field: 'id', minWidth: 60, width: 60 },
                    { field: '@[0].pivot.created_by', displayName: 'Registered By', minWidth: 160, width: 160 },
                    {
                        field: '@[0].pivot.created_at',
                        displayName: 'Registered At ',
                        minWidth: 200,
                        width: 200,
                        cellFilter: "date:'dd-MM-yyyy HH:mm:ss'",
                        type: 'date'
                    },
                ],
                user: [
                    { field: 'name', minWidth: 230, width: 230 },
                    { field: 'email', minWidth: 270, width: 270 },
                    {
                        field: 'password_expiry',
                        minWidth: 160,
                        width: 160,
                        cellFilter: "date:'dd-MM-yyyy'",
                        type: 'date'
                    },
                    { field: 'active', minWidth: 100, width: 100, cellFilter: 'bool' }
                ],
                application: [
                    { field: 'code', minWidth: 70, width: 70 },
                    { field: 'name', minWidth: 230, width: 230 },
                    { field: 'description', minWidth: 350, width: 350 },
                    { field: 'app_url', minWidth: 230, width: 230 },
                    { field: 'partners', minWidth: 200, width: 200 },
                    { field: 'active', minWidth: 100, width: 100, cellFilter: 'bool' }
                ],
                version: [
                    { field: 'application.code', minWidth: 160, width: 160, cellFilter: 'uppercase' },
                    { field: 'version', minWidth: 230, width: 230 },
                    { field: 'log', minWidth: 350, width: 350 }
                ],
                role: [
                    { field: 'application.code', minWidth: 160, width: 160, cellFilter: 'uppercase' },
                    { field: 'name', minWidth: 230, width: 230 },
                    { field: 'description', minWidth: 350, width: 350 },
                    { field: 'administrator', minWidth: 150, width: 150, cellFilter: 'bool' },
                    { field: 'guest', minWidth: 100, width: 100, cellFilter: 'bool' },
                    { field: 'active', minWidth: 100, width: 100, cellFilter: 'bool' }
                ],
                permission: [
                    { field: 'application.code', minWidth: 160, width: 160, cellFilter: 'uppercase' },
                    { field: 'name', minWidth: 230, width: 230 },
                    { field: 'active', minWidth: 100, width: 100, cellFilter: 'bool' }
                ],
                workgroup: [
                    { field: 'application.code', minWidth: 160, width: 160, cellFilter: 'uppercase' },
                    { field: 'name', minWidth: 200, width: 200 },
                    { field: 'description', minWidth: 350, width: 350 },
                    { field: 'letterhead_initial', minWidth: 160, width: 160 },
                    { field: 'letterhead_company', minWidth: 200, width: 200 },
                    { field: 'letterhead_tagline', minWidth: 230, width: 230 },
                    { field: 'letterhead_address', minWidth: 300, width: 300 },
                    { field: 'active', minWidth: 100, width: 100, cellFilter: 'bool' }
                ],
                storage: [
                    { field: 'application.code', minWidth: 160, width: 160, cellFilter: 'uppercase' },
                    { field: 'name', minWidth: 150, width: 150 },
                    { field: 'driver', minWidth: 100, width: 100 },
                    { field: 'host', minWidth: 200, width: 200 },
                    { field: 'database', minWidth: 200, width: 200 },
                    { field: 'username', minWidth: 200, width: 200 },
                    { field: 'read_only', minWidth: 130, width: 130, cellFilter: 'bool' },
                    { field: 'update_only', minWidth: 130, width: 130, cellFilter: 'bool' },
                    { field: 'backup', minWidth: 130, width: 130, cellFilter: 'bool' },
                    { field: 'active', minWidth: 130, width: 130, cellFilter: 'bool' }
                ]
            }
        };

        // Restangular settings
        Restangular.setBaseUrl($rootScope.global.baseUrl + '/api');

        // Restangular request interceptor.
        Restangular.addFullRequestInterceptor(function (element, operation, route, url, headers, params, httpConfig) {
            // Apply permission payload as query string parameter.
            PermissionFactory.applyPayload(params);

            // Refresh JWT token after certain amount of request sent by Restangular.
            // It is done by intercepting every request and after number of request
            // is reached, we send additional request to get the refreshed token.
            if (!$rootScope.global.refreshingToken && $rootScope.global.numberOfRequest >= 50) {
                $rootScope.global.refreshingToken = true;

                JWTFactory.jwtCheck()
                    .then(function () {
                        $rootScope.global.numberOfRequest = 0;
                    })
                    .finally(function () {
                        $rootScope.global.refreshingToken = false;
                    });
            }

            // Increment number of request made.
            $rootScope.global.numberOfRequest++;

            // Avoid auto attach element as request body,
            // because DELETE doesn't accept request body.
            if (operation === "remove") {
                return { headers: headers, params: params, element: null, httpConfig: {} };
            }
        });

        // Set original (un-restangularized) response using response extractor.
        Restangular.setResponseExtractor(function (response) {
            var newResponse = response;

            if (response != '') {
                if (angular.isArray(response)) {
                    newResponse.original = [];
                    angular.forEach(newResponse, function (value, key) {
                        newResponse.original[key] = angular.copy(value);
                    });
                } else {
                    newResponse.original = angular.copy(response);
                }
            }

            return newResponse;
        });

        // Initial jwt check
        JWTFactory.jwtCheck()
            .then(function () {
                // Set booting to false so loading animation is closed and our main app will be shown.
                $rootScope.global.booting = false;

                // App wide broadcast that initial jwt check is passed and we're good to go.
                $rootScope.$broadcast('app.booted');
            });
    }
})();
