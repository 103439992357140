(function () {
    angular
        .module('app')
        .controller('PermissionReportController', PermissionReportController);

    // Define controller dependencies.
    PermissionReportController.$inject = ['$rootScope', '$scope', 'AdvancedSearchFactory'];

    /**
     * Controller for 'report.permission' state.
     *
     * @param $rootScope
     * @param $scope
     * @param AdvancedSearchFactory
     * @constructor
     */
    function PermissionReportController($rootScope, $scope, AdvancedSearchFactory) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            var resource = 'permission';

            $rootScope.global.pageTitle = 'Report Permission';

            $scope.shared.reset();
            $scope.shared.resource = resource;
            $scope.shared.reportNames = [
                { name: 'List', qAdvPreset: {} }
            ];
            $scope.shared.selectedReportName = $scope.shared.reportNames[0];

            AdvancedSearchFactory.reset();
            AdvancedSearchFactory.resource = resource;
            angular.forEach($rootScope.global.searchResourceFields[resource], function (relation) {
                var resourceField = {
                    relation: relation.relation,
                    fields  : angular.copy($rootScope.global.resourceFields.common)
                }
                resourceField.fields.splice.apply(resourceField.fields,
                    [1, 0].concat($rootScope.global.resourceFields[relation.resource]));
                AdvancedSearchFactory.resourceFields.push(resourceField);
            });
        }

        activate();
    }
})();
