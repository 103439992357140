(function () {
    'use strict';

    angular
        .module('app')
        .controller('UserInfoController', UserInfoController);

    // Define controller dependencies.
    UserInfoController.$inject = ['$mdDialog', '$rootScope', 'user', 'APIPartners'];

    /**
     * Controller for user info dialog.
     *
     * @param $mdDialog
     * @param $rootScope
     * @param user
     * @param APIPartners
     * @constructor
     */
    function UserInfoController($mdDialog, $rootScope, user, APIPartners) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable properties.
         */
        vm.user = user;
        vm.APIPartners = APIPartners;

        /**
         * Bindable methods.
         */
        vm.isGuest = $rootScope.global.isGuest;
        vm.selectActiveStorage = selectActiveStorage;
        vm.selectActiveWorkgroup = selectActiveWorkgroup;
        vm.toggleFilterWorkgroup = toggleFilterWorkgroup;
        vm.filterWorkgroupChecked = filterWorkgroupChecked;
        vm.workgroupExistInActiveStorage = workgroupExistInActiveStorage;
        vm.close = close;

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Select active workgroup.
         *
         * @param APIId
         * @param value
         */
        function selectActiveWorkgroup(APIId, value) {
            // Update bindable member.
            vm.activeWorkgroupIndex[APIId] = value;

            // Update local storage variable.
            helpers.setLocalStorageItem('activeWorkgroupIndex', vm.activeWorkgroupIndex);

            // Because we store $index and two different storages can have the same $index value
            // (because they are separated by workgroup), so we need to force ng-model to change
            // by presetting with -1 so that active storage is updated even the $index is same.
            vm.activeStorageIndex[APIId] = -1;

            // Set active storage to the first option.
            vm.selectActiveStorage(APIId, 0);
        }

        /**
         * Select active storage.
         *
         * @param APIId
         * @param value
         */
        function selectActiveStorage(APIId, value) {
            // Update bindable member.
            vm.activeStorageIndex[APIId] = value;

            // Update local storage variable.
            helpers.setLocalStorageItem('activeStorageIndex', vm.activeStorageIndex);

            // Preset selected filter workgroups to be the current active workgroup (main app only).
            if (APIId === user.applications[0].id) {
                vm.filterWorkgroupIndexes = [vm.activeWorkgroupIndex[APIId]];
                helpers.setLocalStorageItem('filterWorkgroupIndexes', vm.filterWorkgroupIndexes);
            }

            // App wide broadcast that active storage
            // has changed so that relevant components
            // can update their value accordingly.
            $rootScope.$broadcast('MainController.activeStorageChanged');
        }

        /**
         * Toggle filter workgroup.
         *
         * @param value
         */
        function toggleFilterWorkgroup(value) {
            // Ignore toggle if user is guest.
            if (vm.isGuest()) return;

            // Find the selected filter workgroup if already checked.
            var index = vm.filterWorkgroupIndexes.indexOf(value);

            if (index >= 0) {
                // Has already been checked, so we toggle it off.
                vm.filterWorkgroupIndexes.splice(index, 1);
            } else {
                // Has not already been checked, so we toggle it on.
                vm.filterWorkgroupIndexes.push(value);
                // Sort selected filter workgroup ids in ascending order.
                vm.filterWorkgroupIndexes.sort();
            }

            // Update local storage variable.
            helpers.setLocalStorageItem('filterWorkgroupIndexes', vm.filterWorkgroupIndexes);
        }

        /**
         * Check if specified filter is selected.
         *
         * @param item
         * @returns {boolean}
         */
        function filterWorkgroupChecked(item) {
            return vm.filterWorkgroupIndexes.indexOf(item) > -1;
        }

        /**
         * Check if workgroup (for report filter) exists in active storage.
         *
         * @param workgroup
         * @returns {boolean}
         */
        function workgroupExistInActiveStorage(workgroup) {
            if (workgroup.storages.length > 0) {
                // If there is an active storage in this
                // workgroup, then this workgroup exists
                // in active storage, so we return true.
                return workgroup.storages.filter(function (storage) {
                    var user = $rootScope.global.user;
                    var mainApp = user.applications[0].id;

                    // Get the active storage.
                    var activeStorage = user
                        .workgroups[vm.activeWorkgroupIndex[mainApp]]
                        .storages[vm.activeStorageIndex[mainApp]];

                    // Check if current storage is an active storage.
                    return (angular.isDefined(activeStorage)) ? storage.id === activeStorage.id : false;
                }).length > 0;
            } else {
                // Workgroup doesn't have storage at all, so we return false.
                return false;
            }
        }

        /**
         * Close dialog.
         */
        function close() {
            $mdDialog.hide();
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Initialize bindable members with value from local storage.
            vm.activeWorkgroupIndex = helpers.getLocalStorageItem('activeWorkgroupIndex');
            vm.filterWorkgroupIndexes = helpers.getLocalStorageItem('filterWorkgroupIndexes');
            vm.activeStorageIndex = helpers.getLocalStorageItem('activeStorageIndex');
        }

        activate();
    }
})();
