(function () {
    angular
        .module('app')
        .controller('ReportController', ReportController);

    // Define controller dependencies.
    ReportController.$inject = ['$mdDialog', '$rootScope', '$scope', '$window', 'AdvancedSearchFactory', 'DataListFactory', 'hotkeys', 'Restangular'];

    /**
     * Controller for 'report' abstract state.
     *
     * @param $mdDialog
     * @param $rootScope
     * @param $scope
     * @param $window
     * @param AdvancedSearchFactory
     * @param DataListFactory
     * @param hotkeys
     * @param Restangular
     * @constructor
     */
    function ReportController($mdDialog, $rootScope, $scope, $window, AdvancedSearchFactory, DataListFactory, hotkeys, Restangular) {
        /**
         * --------------------------------------------
         * Private variables.
         * --------------------------------------------
         */

        /**
         * This controller.
         */
        var vm = this;

        var sharedDefault = {
            resource: null,
            loading: null,
            qAdv: null,
            reportNames: [],
            reportFormats: ['PDF', 'XLS', 'CSV'],
            selectedReportName: null,
            selectedReportFormat: 'PDF',
            reportGenerated: false,
            reset: reset
        };

        /**
         * --------------------------------------------
         * Bindable members.
         * --------------------------------------------
         */

        /**
         * Bindable methods.
         */

        vm.showAdvancedSearch = showAdvancedSearch;
        vm.search = search;

        /**
         * Report Controller level shared object that extends
         * its parent controller (MainController) and will
         * be accessible for all of its child controllers.
         */
        $scope.shared = angular.extend(
            // New shared object.
            {},
            // Shared property from parent controller (MainController).
            $scope.shared,
            // This controller shared property.
            angular.copy(sharedDefault)
        );

        /**
         * --------------------------------------------
         * Private methods.
         * --------------------------------------------
         */

        /**
         * Reset report controller.
         */
        function reset() {
            angular.element(document.querySelector('#report-embedded')).remove();
            $scope.shared = angular.copy(sharedDefault);
        }

        /**
         * --------------------------------------------
         * Bindable member implementations.
         * --------------------------------------------
         */

        /**
         * Show advanced search dialog.
         */
        function showAdvancedSearch() {
            AdvancedSearchFactory.show()
                .then(function (qAdv) {
                    $scope.shared.qAdv = qAdv;
                    vm.search();
                });
        }

        /**
         * Search data and generate report.
         */
        function search() {
            // Remove previous report viewer.
            angular.element(document.querySelector('#report-embedded')).remove();

            $scope.shared.reportGenerated = false;
            $scope.shared.loading = true;

            // Send request to get report as blob.
            Restangular.withConfig(function (RestangularConfigurer) { RestangularConfigurer.setFullResponse(true); })
                .one($scope.shared.resource).withHttpConfig({responseType: 'blob'}).customGET('report', {
                    q: AdvancedSearchFactory.extractQAdv($scope.shared.qAdv),
                    reportName: $scope.shared.selectedReportName.name,
                    reportFormat: $scope.shared.selectedReportFormat,
                    user: $rootScope.global.user.id,
                    workgroup: $rootScope.global.user.workgroups[helpers.getLocalStorageItem('activeWorkgroupIndex')[$rootScope.global.user.applications[0].id]].id
            })
                .then(function (result) {
                    var filename = result.headers('Content-Disposition').split(';')[1].split('=')[1].replace(/"/g, '');

                    saveAs(result.data, filename);

                    $scope.shared.reportGenerated = true;
                })
            .finally(function () {
                $scope.shared.loading = false;
            });
        }

        /**
         * --------------------------------------------
         * Controller activation.
         * --------------------------------------------
         */

        /**
         * Activate controller.
         */
        function activate() {
            // Apply preset advanced search query if selected report name changed.
            $scope.$watch('shared.selectedReportName', function (newValue) {
                AdvancedSearchFactory.qAdvPreset = newValue.qAdvPreset;
            });

            // Binding keyboard shortcuts.
            hotkeys.bindTo($scope)
                .add({
                    combo: 'shift+ctrl+alt+f',
                    description: 'Open advanced search',
                    allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                    callback: function () { vm.showAdvancedSearch(); }
                });
        }

        activate();
    }
})();
