(function () {
    'use strict';

    angular
        .module('app')
        .filter('fieldName', fieldName)
        .filter('bool', bool)
        .filter('array', array)
        .filter('invalidDate', invalidDate)
        .filter('display', display)
        .filter('displayMany', displayMany);

    // Define fieldName filter dependencies.
    fieldName.$inject = [];

    /**
     * Normalize snake cased or dotted field name.
     * e.g. field_name.nested_item -> Field Name Nested Item
     *
     * @returns {Function}
     */
    function fieldName() {
        return function (input) {
            var split = [];

            input = input.replace(/\./g, ' ').replace(/_/g, ' ');

            split.splice.apply(split, [split.length, 0].concat(
                helpers.stringContains(' ', input) ? input.split(' ') : [input]
            ));

            split = split.map(function (item) {
                return _.capitalize(item);
            });

            return split.join(' ');
        }
    }

    // Define bool filter dependencies.
    bool.$inject = [];

    /**
     * Stringify boolean value.
     * e.g. true -> 'TRUE'; 0 -> 'FALSE'
     *
     * @returns {Function}
     */
    function bool() {
        return function (input) {
            if (input === 0 || input === false) {
                return 'FALSE';
            } else if (input === 1 || input === true) {
                return 'TRUE';
            } else {
                return '';
            }
        }
    }

    // Define array filter dependencies.
    array.$inject = [];

    /**
     * Stringify array value.
     * e.g. ['one', 'two'] -> 'one, two'
     *
     * @returns {Function}
     */
    function array() {
        return function (input) {
            return Array.isArray(input) ? input.join(', ') : '';
        }
    }

    /**
     * Stringify Invalid Date value.
     * e.g. Invalid Date -> ''; '' -> ''
     *
     * @returns {Function}
     */
    function invalidDate() {
        return function (input) {
            return input.toString() == 'Invalid Date' ? '' : input;
        }
    }

    // Define display filter dependencies.
    display.$inject = [];

    /**
     * Display string representation of an item with the specified input as the id.
     * e.g.
     *  given:
     *      source = [{id: 1, name: 'one'}, {id: 2, name: 'two'}];
     *      displayField = 'name';
     *      valueField = 'id';
     *  then:
     *      1 -> 'one'; 2 -> 'two'
     *
     * @returns {Function}
     */
    function display() {
        return function (input, source, displayField, valueField) {
            if (input == undefined || source == undefined || input == null || source == null) return '';

            return _.get(_.find(source, valueField || 'id', input), displayField, '');
        }
    }

    // Define displayMany filter dependencies.
    displayMany.$inject = [];

    /**
     * Display string representation of comma separated items with the specified input as the comma separated ids.
     * e.g.
     *  given:
     *      source = [{id: 1, name: 'one'}, {id: 2, name: 'two'}];
     *      displayField = 'name';
     *      valueField = 'id';
     *  then:
     *      '1,2' -> 'one, two'
     *
     * @returns {Function}
     */
    function displayMany() {
        return function (input, source, displayField, valueField) {
            if (input == undefined || source == undefined || input == null || source == null) return '';

            return input.map(function (id) {
                // We simply use display filter above to display single id.
                return (display())(parseInt(id), source, displayField, valueField);
            }).join(', ');
        }
    }

})();
