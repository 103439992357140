(function () {
    'use strict';

    angular
        .module('app')
        .factory('DataListFactoryProvider', DataListFactoryProvider)
        .factory('DataListFactory', ['DataListFactoryProvider', function (DataListFactoryProvider) {
            // Get factory instance from the provider.
            return DataListFactoryProvider.getInstance();
        }]);

    // Define factory provider dependencies.
    DataListFactoryProvider.$inject = ['$q', '$rootScope', '$state', '$timeout', 'AdvancedSearchFactoryProvider', 'FocusService', 'Restangular'];

    /**
     * Factory provider that gives data list functionality.
     *
     * @param $q
     * @param $rootScope
     * @param $state
     * @param $timeout
     * @param AdvancedSearchFactoryProvider
     * @param FocusService
     * @param Restangular
     * @returns {{getInstance: Function}}
     * @constructor
     */
    function DataListFactoryProvider($q, $rootScope, $state, $timeout, AdvancedSearchFactoryProvider, FocusService, Restangular) {

        /**
         * Service provider.
         *
         * @constructor
         */
        var ServiceProvider = function () {
            /**
             * --------------------------------------------
             * Private variables.
             * --------------------------------------------
             */

            var MAX_TAKE = 1000;

            /**
             * --------------------------------------------
             * Factory members.
             * --------------------------------------------
             */

            var service = {
                // Properties
                gridOptionsDefault: {
                    onRegisterApi: onRegisterApi,
                    enableColumnResizing: true,
                    enableSelectionBatchEvent: false,
                    multiSelect: true,
                    enableRowSelection: true,
                    enableRowHeaderSelection: true,
                    modifierKeysToMultiSelect: false,
                    enablePagination: false,
                    enablePaginationControls: false,
                    columnDefs: [],
                    loading: null,
                    page: 0,
                    take: 10,
                    totalPages: 0,
                    totalRows: 0
                },
                resource: null,
                q: null,
                qAdv: null,
                gridApi: null,
                gridOptions: null,
                quickSearchDisabled: false,
                contextSelector: '',
                resetWhileBusy: false,
                advancedSearchFactory: AdvancedSearchFactoryProvider.getInstance(),
                // Methods
                search: search,
                showAdvancedSearch: showAdvancedSearch,
                first: first,
                previous: previous,
                next: next,
                last: last,
                reset: reset,
                focus: focus,
                isBusy: isBusy,
                setBusy: setBusy,
                setNotBusy: setNotBusy,
                selectSingleRow: selectSingleRow,
                advancedSearchDialogClosedCallback: null
            };

            /**
             * --------------------------------------------
             * Factory activation.
             * --------------------------------------------
             */

            function activate() {
                service.gridOptions = angular.copy(service.gridOptionsDefault);
            }

            activate();

            return service;

            /**
             * --------------------------------------------
             * Private methods.
             * --------------------------------------------
             */

            /**
             * UI-Grid callback for onRegisterApi event.
             * We use this to get the reference for grid
             * api and also registering ui-grid events.
             *
             * @param gridApi
             */
            function onRegisterApi(gridApi) {
                // set gridApi on scope
                service.gridApi = gridApi;

                // capturing if shift key is pressed
                var shiftHeld = false;
                document.onkeydown = function(e) {
                    shiftHeld = e.shiftKey;
                };
                document.onkeyup = function(e) {
                    shiftHeld = e.shiftKey;
                };

                // select row on cell nav changed
                service.gridApi.cellNav.on.navigate($rootScope.$new(), function(newCell, oldCell) {
                    if (newCell.col.name !== "selectionRowHeaderCol") {
                        if (!shiftHeld) service.gridApi.selection.clearSelectedRows();
                        service.gridApi.selection.selectRow(newCell.row.entity);
                        service.gridApi.core.refreshRows();
                    }
                });

                // Clear current cell on grid blur
                $timeout(function () {
                    angular.element('#data-list .ui-grid-focuser').blur(function () {
                        service.gridApi.grid.cellNav.clearFocus();
                        service.gridApi.grid.cellNav.lastRowCol = null;
                    });
                });
            }

            /**
             * --------------------------------------------
             * Factory members implementations.
             * --------------------------------------------
             */

            /**
             * Retrieve total row count and paginated data from the API.
             *
             * @returns {*}
             */
            function search() {
                // We allow search request to be executed only if previous
                // search request has been completed. Otherwise, we simply
                // return void to ignore the latest search request.
                if (service.isBusy()) return;

                // Check if it is advanced search.
                var advanced = (service.qAdv != null || service.quickSearchDisabled) ? true : false;

                // Initialize qAdv if it hasn't been initialized.
                if (advanced && service.qAdv == null) {
                    service.qAdv = service.advancedSearchFactory.getQAdvDefault();
                }

                // Limit number of taken rows per page
                service.gridOptions.take = Math.min(service.gridOptions.take, MAX_TAKE);

                // Set service busy.
                service.setBusy();

                // Clear previous bound data.
                service.gridOptions.data = [];

                // Get the data and return it.
                return getTotalRows()
                    .then(getPaginatedRows)
                    .finally(function () {
                        service.setNotBusy();
                    });

                /**
                 * Get total row count which will be used for generating paginated data.
                 *
                 * @returns Promise
                 */
                function getTotalRows() {
                    return Restangular.one(service.resource).get({
                        q: advanced ? service.advancedSearchFactory.extractQAdv(service.qAdv) : service.q,
                        c: true
                    })
                        .then(function (result) {
                            // Get total rows.
                            service.gridOptions.totalRows = parseInt(result.original['count']);

                            // Get total pages.
                            service.gridOptions.totalPages = Math.ceil(service.gridOptions.totalRows / service.gridOptions.take);

                            if (service.gridOptions.totalPages == 0)
                                // No data found, set page to 0.
                                service.gridOptions.page = 0;
                            else if (service.gridOptions.page == 0 && service.gridOptions.totalPages > 0
                                || service.gridOptions.page > service.gridOptions.totalPages)
                                // Data found and user has not set the selected page or
                                // new total pages is greater than previous selected
                                // page, so we set first page as the selected page.
                                service.gridOptions.page = 1;

                            // No data found, return reject promise of empty result.
                            if (service.gridOptions.totalRows == 0) return $q.reject('empty result');
                        }, function () {
                            // Bad response received, set pagination properties to 0.
                            service.gridOptions.totalRows = 0;
                            service.gridOptions.totalPages = 0;
                            service.gridOptions.page = 0;

                            // Bad response, return reject promise of bad response.
                            return $q.reject('bad response');
                        });
                }

                /**
                 * Get paginated data.
                 *
                 * @returns Promise
                 */
                function getPaginatedRows() {
                    return Restangular.all(service.resource).getList({
                        q: advanced ? service.advancedSearchFactory.extractQAdv(service.qAdv) : service.q,
                        d: 0,
                        skip: (service.gridOptions.page - 1) * service.gridOptions.take,
                        take: service.gridOptions.take
                    })
                        .then(function (result) {
                            // Convert date string to Date object so that sorting function of the grid will work.
                            service.gridOptions.columnDefs.forEach(function (columnDef) {
                                // Convert only for 'date' type column and the date
                                // format is YYYY-MM-DD HH:mm:ss, which is the
                                // non-decorated date format from the API.
                                if (columnDef.type == 'date') {
                                    result.original.forEach(function (item) {
                                        if (helpers.stringContains('.', columnDef.field)) {
                                            // Nested field
                                            var dateString = _.get(item, columnDef.field),
                                                dateField = '',
                                                dateContainer = (function () {
                                                    var splitted = columnDef.field.split('.');
                                                    dateField = splitted.pop();
                                                    return _.get(item, splitted.join('.'));
                                                })();
                                            dateContainer[dateField] = moment(dateString, 'YYYY-MM-DD HH:mm:ss.000').toDate();
                                        } else {
                                            // Non nested field.
                                            item[columnDef.field] = moment(item[columnDef.field], 'YYYY-MM-DD HH:mm:ss.000').toDate();
                                        }
                                    });
                                }
                            });

                            // Set new bound data.
                            service.gridOptions.data = result.original;

                            // Focus on first cell
                            $timeout(function () {
                                service.focus();
                            }, 1);

                            // manually call handleWindowResize instead of
                            // using auto-resize due to flickering effect
                            $timeout(function () {
                                service.gridApi.core.handleWindowResize();
                            }, 1);
                        });
                }
            }

            /**
             * Show the advanced search dialog.
             */
            function showAdvancedSearch() {
                // If previous search is in progress, don't show the dialog.
                if (service.isBusy()) return;

                // Show the dialog by passing the previous advanced query.
                service.advancedSearchFactory.show()
                    .then(function (qAdv) {
                        // Store updated advanced query.
                        service.qAdv = qAdv;
                        // Clear simple query.
                        service.q = null;
                        // Search using advanced query.
                        service.search();
                    })
                    .finally(function () {
                        // Call the advancedSearchDialogClosedCallback which
                        // is used to 're-show' the lookup dialog after
                        // this advanced search dialog is closed.
                        if (service.advancedSearchDialogClosedCallback) service.advancedSearchDialogClosedCallback();
                    });
            }

            /**
             * Go to first page.
             */
            function first() {
                if (service.isBusy()) return $q.reject();
                if (service.gridOptions.page > 1) {
                    service.gridOptions.page = 1;
                    return service.search();
                } else {
                    return $q.reject();
                }
            }

            /**
             * Go to previous page.
             */
            function previous() {
                if (service.isBusy()) return $q.reject();
                if (service.gridOptions.page - 1 >= 1) {
                    service.gridOptions.page--;
                    return service.search();
                } else {
                    return $q.reject();
                }
            }

            /**
             * Go to next page.
             */
            function next() {
                if (service.isBusy()) return $q.reject();
                if (service.gridOptions.page + 1 <= service.gridOptions.totalPages) {
                    service.gridOptions.page++;
                    return service.search();
                } else {
                    return $q.reject();
                }
            }

            /**
             * Go to last page.
             */
            function last() {
                if (service.isBusy()) return $q.reject();
                if (service.gridOptions.page < service.gridOptions.totalPages) {
                    service.gridOptions.page = service.gridOptions.totalPages;
                    return service.search();
                } else {
                    return $q.reject();
                }
            }

            /**
             * Reset data list.
             */
            function reset() {
                // Set flag that indicating that we reset data list while it's still searching.
                if (service.isBusy()) {
                    service.resetWhileBusy = true;
                }

                service.gridApi = null;
                service.gridOptions = angular.copy(service.gridOptionsDefault);
                service.updateDisabled = false;
                service.destroyDisabled = false;
                service.resource = null;
                service.q = null;
                service.qAdv = null;
                service.quickSearchDisabled = false;
                service.advancedSearchDialogClosedCallback = null;
            }

            /**
             * Set focus to the first cell.
             */
            function focus() {
                service.gridApi.cellNav.scrollToFocus(
                    service.gridOptions.data[0],
                    service.gridOptions.columnDefs[0]
                );
                FocusService.focus(service.contextSelector + ' .ui-grid-cell-focus');
            }

            /**
             * Check if data list is still searching.
             *
             * @returns {boolean}
             */
            function isBusy() {
                return service.gridOptions.loading === true;
            }

            /**
             * Set data list is still searching.
             */
            function setBusy() {
                service.gridOptions.loading = true;
            }

            /**
             * Set data list is not searching.
             */
            function setNotBusy() {
                if (service.resetWhileBusy) {
                    // We skip setting data list to 'not busy' so that loading
                    // animation will keep showing when the response from a
                    // search is resolved but the data list has been reset.
                    service.resetWhileBusy = false;
                } else {
                    service.gridOptions.loading = false;
                }
            }

            /**
             * Select only single row and deselect the others
             */
            function selectSingleRow() {
                var counter = 0;
                service.gridApi.selection.getSelectedGridRows().forEach(function (gridRow) {
                    if (counter > 0) {
                        gridRow.setSelected(false);
                    } else {
                        service.gridApi.cellNav.scrollToFocus(
                            gridRow.entity,
                            service.gridApi.grid.options.columnDefs[0]
                        )
                    }
                    counter++;
                });
                return service.gridApi.selection.getSelectedGridRows()[0];
            }
        };

        // Return DataListFactory provider object
        return {
            getInstance: function () {
                return new ServiceProvider();
            }
        };
    }
})();
